import _ from 'lodash';

export const heartStructure = (body, activeUser, action) => {

  return {
    post: {
      id: body.id || body._id,
      title: body.title,
      topic: {
        names: _.get(body, 'topics', []).map(topic => body.type !== 'POLL' ? topic.title : topic.hash_tag),
      },
      primary_topic: body.card_data?.['card-author-byline'],
      type: body.type !== 'POLL' ? _.get(body, 'type', '') : (body.poll_type === 'SINGLE' ? 'poll_single' : 'poll_multi'),
      user: {
        id: body.author?._id,
        username: body.author?.username,
      },
    },
    target: {
      type: body.type === 'POLL' ? 'poll' : action,
    },
    userId: activeUser._id,
    context: {
      ...body.groups[0]?._id ? {
        group: {
          id: body.groups[0]._id,
          name: body.groups[0].name,
        },
      } : {},
    }
  }
}

export const shareStructure = (body, activeUser, action) => {
  return {
    post: {
      id: body.id || body._id,
      title: body.title,
      topic: {
        names: _.get(body, 'topics', []).map(topic => body.type === 'POLL' ? topic.hash_tag : topic.title),
      },
      type: body.type !== 'POLL' ? _.get(body, 'type', '') : (body.poll_type === 'SINGLE' ? 'poll_single' : 'poll_multi'),
      user: {
        id: body.author?._id || body.author_id?._id,
        username: body.author?.username || body.author_id?.username,
      },
    },
    target: {
      type: body.type === 'POLL' ? 'poll' : 'post',
      name: action,
    },
    userId: activeUser._id,
    context: {
      ...body.groups[0]?._id ? {
        group: {
          id: body.groups[0]._id,
          name: body.groups[0].name,
        },
      } : {},
    }
  }
}

export const saveStructure = ({ body, activeUser, type }) => {

  return {
    post: {
      id: body.id || body._id,
      title: body.title,
      type: type || '',
    }
  }
};
