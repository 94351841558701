import { SpotlightPropsI } from '@/userExperienceRedesign/modules/Spotlight/types';
import SponsorController from '@/userExperienceRedesign/controllers/sponsor';

export default class SpotlightController {
  protected data?: SpotlightPropsI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    this.data = {
      post,
      ctaLabel: post?.spotlight?.rsvp_text,
      ctaUrl: post?.spotlight?.rsvp_link,
      description: post?._normalized_body,
      imageSrc: post?.spotlight?.image_src || null,
      label: post?.spotlight?.subtitle,
      name: post?._normalized_title,
      sponsor: new SponsorController(post).value,
      // avatarGroup: string[],
      // avatarGroupBlurb?: string,
    };
  }
}
