
import { shuffle } from 'lodash';
import { mapGetters } from 'vuex';
import { staticPath } from '@/data/helpers';
import { debounce } from 'lodash';

export default {
    name: 'HomeQuickSearch',
    data() {
        return {
            hovered: null,
            isMobile: false,
            running: false,
        }
    },
  async mounted() {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    this.isMobile = mediaQuery.matches;
  },
    computed: {
      ...mapGetters({
        getFeaturedTopics: 'getFeaturedTopics',
      }),
      tabs() {
        return shuffle(this.getFeaturedTopics);
      },
    },
    methods: {
        staticPath,
        handleHover(value, setting) {
            if (setting === 'in') {
                this.hovered = value;
            } else if (value === this.hovered) {
                this.hovered = null;
            }
        },
        filterScroll(dir) {
            const filter = document.querySelector('#filters-scroll');
            if (dir === 'right') {
                filter.scrollLeft += 200;
            } else if (dir === 'left') {
                filter.scrollLeft -= 200;
            }
        },
        debounceFilterScroll: debounce(function() { this.newScroll(); }, 100),
        newScroll() {
            // mobile scrolling is jumpy so a lot of intricacies to get it smooth, just guess and check
            let container, rightScroll, leftScroll;
            container = document.querySelector('#filters-scroll');
            rightScroll = document.querySelector('#right-scroll');
            leftScroll = document.querySelector('#left-scroll');

            if (this.running) {
                return;
            }

            this.running = true;

            if (!this.isMobile) {
            container.style.paddingRight = '25px';
            rightScroll.style.display = 'flex';
            }
            leftScroll.style.display = 'flex';
            container.style.paddingLeft = '0px';


            if (container.scrollLeft === 0) {
            leftScroll.style.display = 'none';
            container.style.paddingLeft = '10px';
            } else if (container.scrollLeft + container.offsetWidth + 35 >= container.scrollWidth) {
            rightScroll.style.display = 'none';
            } else if (this.isMobile) {
            rightScroll.style.display = 'flex';
            }

            this.running = false;
        },
    },
}
