
/* eslint no-console:0 */
// libs
import $ from 'jquery';
import AddTagButton from '@/components/AddTagButton';
import { log, isSet, getWindowSize } from '../../data/helpers';
import FieldLabel from '../FieldLabel';
import InstructionText from '../InstructionText';
import HelperMark from '../HelperMark';
import CountUp from '../CountUp';
import CountDown from '../CountDown';

export default {
  name: 'MultilineTextBox',
  components: { FieldLabel, InstructionText, HelperMark, CountUp, CountDown, AddTagButton },
  props: ['args', 'singleLine'],
  data() {
    return {
      // is the element focused currently?
      focused: false,

      // what is the element id?
      id: '',

      atCharLimit: false,
    };
  },

  // calculated properties
  computed: {
    // make value a computed property instead. works better this way because it can relay data on it's own
    value: {
      get() {
        return this.args.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    // should we be showing the count-up module?
    showCountUp() {
      // if there are no args that allow us to show the count-up module, then auto fail
      if (!this.args.showCountUp || !this.args.atts || !this.args.atts['data-max-length']) {
        return false;
      }

      const winSize = getWindowSize();
      // are we in desktop mode? if so, auto succeed
      if (winSize.width > 768) {
        return true;
      }

      // otherwise, only show if this element is focused (since it is absolute positioned at the bottom
      return this.focused;
    },

    // should we be showing the count-down module?
    showCountDown() {
      // if there are no args that allow us to show the count-up module, then auto fail
      if (!this.args.showCountDown || !this.args.atts || !this.args.atts['data-max-length']) {
        return false;
      }

      const winSize = getWindowSize();
      // are we in desktop mode? if so, auto succeed
      if (winSize.width > 768) {
        return true;
      }
      return true;

      // otherwise, only show if this element is focused (since it is absolute positioned at the bottom
      // return this.focused;
    },

    showAddHashtags() {
      // return true;
      return this.args.showHashtagsButton;
    },

    // get the current value length, for the count-up module
    getLength() {
      return this.value.length;
    },

    // get the max length, for the count-up module
    getMax() {
      return this.args.atts ? this.args.atts['data-max-length'] : 0;
    },

    // get the length at which to start warning the user
    getWarnAt() {
      return this.args.atts && isSet(this.args.atts['data-warn-at']) ? parseInt(this.args.atts['data-warn-at'], 10) : 10;
    },

    // limit message
    getLimitMsg() {
      return this.args.atts && this.args.atts['data-limit-msg'] ? this.args.atts['data-limit-msg'] : '';
    },
  },

  // normalize input data
  beforeMount() {
    this.args.value = '';
  },

  // when this element is rendered, normalize the args
  mounted() {
    this.args.atts = typeof this.args.atts === 'object' ? this.args.atts : {};
    this.args.autosize = !!this.args.autosize;
  },

  // handle a few events
  methods: {
    // when the textbox is focused, switch flag, and update parent
    gotFocused() {
      this.focused = true;
      this.$emit('focus', { target: this.$refs.wrap });
    },

    // when the textbox is blurred, switch flag, and update parent
    gotBlurred(evt) {
      evt.stopImmediatePropagation();
      // focus is moving to hashtags button
      if (this.isFocusHashtagUI(evt)) {
        return;
      }
      // focus moving to somewhere else
      this.focused = false;
      this.$emit('blur', { target: this.$refs.wrap });
    },

    // focus moved to the #hashtags button or hashtags search results
    isFocusHashtagUI(evt) {
      const focusTarget = evt.relatedTarget || document.activeElement;
      if (focusTarget &&
          $(focusTarget).closest('.add-hashtags-wrap, .results-modal, .result-wrap').length) {
        return true;
      }
      return false;
    },

    // update vuex with this being the last edited field. this helps handle fancy shit like the border around checkbox lists
    focusInput() {
      this.$store.commit('setLastEditedField', this.$vId);
      log('SAFARI', 'accepting click event on textarea, and passing on focus event', this.$el, $(this.$el).find('textarea:eq(0)').get(0));
      // $(this.$el).find('textarea:eq(0)').get(0).focus();
    },

    limitReached(reached) {
      this.atCharLimit = reached;
    },

    sendValue() {
      this.$emit('input', this.value);
    },

    startQuery(text) {
      const textbox = this.$refs.textbox;

      // sets textbox value
      textbox.value += text;

      // sets focus and selection
      textbox.click();
      textbox.focus();
      textbox.setSelectionRange(textbox.value.length, textbox.value.length);

      // trigger change value event
      this.$emit('input', textbox.value);
    },
  },
};
