
import FormButton from '@/components/FormButton';
import { staticPath } from '@/data/helpers';

export default {
  name: 'SeeMoreButton',
  // we use these components inside this component
  components: { FormButton },

  // internal data
  data() {
    return {
      loading: false,
      // loading more icon
      loadingIcon: `<span class="img-wrap-36"><img src="${staticPath('loading-ellipsis.svg')}" class="icon50" width="50"/></span>`,
    };
  },

  // these are passed in from elsewhere
  props: {
    feedName: {
      default: '',
    },
    options: {
      type: Object,
      default: () => ({
        offset: 0,
        limit: 10,
      }),
    },
    gaCategory: {
      default: '',
    },
  },

  methods: {
    staticPath,
    loadMoreItems() {
      const trending = this.options.trending;
      const types = this.options.types;
      this.loading = true;
      this.$store.commit('triggerFeedLoadMore', { options: this.options, gaCategory: this.gaCategory });
      const args = { feedName: this.feedName, offset: this.options.offset, limit: this.options.limit, trending, types, latest: this.feedName === 'posts' };
      this.$store.dispatch('loadFeedItems', args)
        .then(() => {
          this.loading = false;
        });
    },
  },
};

/**
 * @NOTE - v-if on each modal below makes it so that when the modal becomes active, the modal's mounted() function is called. otherwise, you cannot distinguish
 *         modal creation from modal data update
 */
