
import vue from 'vue';
import BellIcon from '@/userExperienceRedesign/components/Icon/bellIcon.vue';

export default vue.extend({
  name: 'PostCommentButton',
  components: { BellIcon },
  props: {
    /** Asks if comment button is on dark background  */
    isOnDarkBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updateTextColor() {
      if (this.isOnDarkBg) {
        return 'socialButton--onDark';
      }
      return '';
    },
  },
  methods: {},
});
