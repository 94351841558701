
import { mapGetters } from 'vuex';
import { isSet, isObject } from '@/data/helpers';
import Responsive from '@/mixins/responsive';
import NavbarHeight from '@/mixins/navbar-height';

export default {
  name: 'FeaturedFeed',
  mixins: [Responsive, NavbarHeight],
  components: {
    // need to import it this way because of recursive component calling
    // ie. FeaturedFeed uses BaseFeed which uses FeaturedFeed
    BaseFeed: () => import('../BaseFeed')
   },
  data() {
    return {
      name: 'featured',
      feedMessage: '',
      stickyPosition: 0,
    };
  },

  props: {
    gaCategory: {
      default: '',
    },
    loading: {
      default: false
    },
    parentFeed: {
      default: null,
    }
  },
  mounted() {
    if (window.innerHeight < parseInt(this.navbarHeight, 10) + this.$refs.featureFeed.clientHeight) {
      this.stickyPosition = (window.innerHeight + parseInt(this.navbarHeight, 10)) - this.$refs.featureFeed.clientHeight - 200;
    } else {
      this.stickyPosition = parseInt(this.navbarHeight, 10) + 24;
    }
  },

  computed: {
    ...mapGetters({
      user: 'user',
    }),

    // determine if the user is logged in
    userLoggedIn() {
      return isObject(this.user) && isSet(this.user.username);
    },

    feedCardOptions: () => ({
      humanNumber: true,
      humanNumberFirstValue: false,
      utmCampaignPrefix: 'home_feed.recommended',
    }),
  },
};

/**
 * @NOTE - v-if on each modal below makes it so that when the modal becomes active, the modal's mounted() function is called. otherwise, you cannot distinguish
 *         modal creation from modal data update
 */
