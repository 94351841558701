import { SponsorI } from '../types/sponsor';

export default class SponsorController {
  protected data?: SponsorI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    if (!post.sponsored) return;
    this.data = {
      post,
      label: post?.sponsored?.text,
      logoSrc: post?.sponsored?.logo,
      name: post?.sponsored?.name,
      url: post?.sponsored?.url,
      is: post?.sponsored?.is,
    };
  }
}
