
import Vue, { PropType } from 'vue';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import VueResizeObserver from 'vue-resize-observer';
import segmentAnalytics from '@/libs/events';
import { staticPath, getStoryUrl, doesUserOwnPost } from '@/data/helpers';
import CommunityLabel from '@/userExperienceRedesign/components/CommunityLabels/CommunityLabel.vue';
import OutlinedButton from '@/userExperienceRedesign/components/OutlinedButton/index.vue';
import PeopleAreTalkingAboutThis from '@/userExperienceRedesign/components/PeopleAreTalkingAboutThis/index.vue';

import SocialButtonGroup from '@/userExperienceRedesign/components/LikeShareButtons/SocialButtonGroup.vue';
import CardFooter from '@/feed/FeedCard/CardFooter.vue';
import FeedCardActionMenu from '@/feed/FeedCard/FeedCardActionMenu.vue';

import StoryAuthorInfo from '@/userExperienceRedesign/components/StoryAuthorInfo/index.vue';
import SponsorHeading from '@/userExperienceRedesign/components/SponsorHeading/index.vue';

import { StoryTileProps } from './types';
// TODO, moves this into a entry point js files
Vue.use(VueResizeObserver);

export const sharedStoryTileProps = {
  post: {
    type: Object,
    required: true,
  },
  index: {
    required: true,
  },
  options: {
    required: true,
  },
  gaCategory: {
    required: true,
  },
  segmentData: {
    type: Object,
    required: true,
  },
  /**
   * Data for the author of the post
   */
  author: {
    type: Object as PropType<StoryTileProps['author']>,
    required: true,
  },
  /**
   * An array of avatar URL strings for the people who reacted to the post
   */
  avatarGroup: {
    type: Array as PropType<StoryTileProps['avatarGroup']>,
  },
  /**
   * Text to display next to the avatar group of people who reacted to the post
   */
  avatarGroupBlurb: {
    type: String as PropType<StoryTileProps['avatarGroupBlurb']>,
  },
  /**
   * Data for how the category should be labeled
   */
  category: {
    type: Object as PropType<StoryTileProps['category']>,
  },
  /**
   * A brief block of introduction text to give the user a preview of what the post is
   */
  contentPreview: {
    type: String as PropType<StoryTileProps['contentPreview']>,
    required: true,
  },
  /**
   * The URL for the full post
   */
  fullStoryURL: {
    type: String as PropType<StoryTileProps['fullStoryURL']>,
    required: true,
  },
  /**
   * The post's title
   */

  headline: {
    type: String as PropType<StoryTileProps['headline']>,
    required: true,
  },
  /**
   * The image associated with the post
   */
  imageSrc: {
    type: String as PropType<StoryTileProps['imageSrc']>,
  },
  /**
   * Sponsor data for when we have a sponsored story
   */
  sponsor: {
    type: Object as PropType<StoryTileProps['sponsor']>,
  },
  /**
   * The number of people who have reacted to the story.
   */
  reactionsCount: {
    type: Number as PropType<StoryTileProps['reactionsCount']>,
  },
  expandStory: {
    type: Boolean,
    required: true,
  },
};

export default Vue.extend({
  name: 'StoryTile',
  components: { CommunityLabel, OutlinedButton, PeopleAreTalkingAboutThis, CardFooter, SocialButtonGroup, SponsorHeading, StoryAuthorInfo, FeedCardActionMenu },
  data: () => ({
    isPreviewExpanded: false,
    showFullStoryCallToAction: true,
  }),
  props: {
    ...sharedStoryTileProps,
    /**
     * When the content preview text is too long to fit onto 5 lines, a button is shown to show the full block of text.
     * Whatever text is passed here will be rendered inside the button
     */
    expandButtonLabel: {
      type: String,
      default: 'Show More',
    },
    /**
     * At the end of the preview text, there is a button that links to the full story.
     * Whatever text is passed here will be rendered inside the button
     */
    fullViewButtonLabel: {
      type: String,
      default: 'Continue Reading',
    },
    cameFrom: {
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    isAuthor(): boolean {
      return doesUserOwnPost(this.user, this.post);
    },
    isVideo(): boolean {
      return this.post.type === 'WORDPRESS_VIDEO';
    },
    isSponsored(): boolean {
      return this.sponsor ? this.sponsor.is : false;
    },
    mediaThumb(): any {
      return _.get(this.post, 'video.media_thumb');
    },
  },
  methods: {
    staticPath(url: String) {
      return staticPath(url);
    },
    showTrends() {
      return false; // @TODO bring back PeopleTalkingAboutThis Component: this.reactionsCount
    },
    showCategory() {
      return false; // @TODO bring back the original value when it's time: category.label && category.color
    },
    expandPreview() {
      this.isPreviewExpanded = true;
    },
    openFullStory() {
      /**
       * take user to story landing page on desktop
       * This is to ensure right rail ads show
       */
      if (this.cameFrom) {
        window.open(`${this.fullStoryURL}/?event=${this.cameFrom}`, '_blank');
      } else {
        window.open(this.fullStoryURL, '_blank');
      }
      return;
    },
    openStory(event: any) {
      // Do not do an onClick when the story is expanded.
      if (this.isPreviewExpanded) {
        event.preventDefault();
        return false;
      }

      segmentAnalytics.choose(this.segmentData);
      // Button display logic. If Showing the new button, handle it.
      if (this.showFullStoryCallToAction) {
        const currentPath = window.location.pathname;
        this.clickHandler(currentPath);

        // For Videos that are play in stream, open in new tab
        if (_.get(this.post, 'type') === 'WORDPRESS_VIDEO' && _.get(this.post, 'card_data.play-video-in-stream') === 'yes') {
          event.preventDefault();
          // openFullStory doesn't open new tabs for smaller devices
          if (this.cameFrom) {
            window.open(`${this.fullStoryURL}/?event=${this.cameFrom}`, '_blank');
          } else {
            window.open(this.fullStoryURL, '_blank');
          }
          return false;
        }

        // Toggle Open Full Story modal handling.
        if (this.expandStory) {
          event.preventDefault();
          this.openFullStory();
          return false;
        }
      } else {
        // Old button handling, fall back if "Show Full Story" is not set.
        event.preventDefault();
        this.expandPreview();
        return false;
      }

      // Fallback, go to the Story.
      if (this.cameFrom) {
        window.location.href = `${this.fullStoryURL}/?event=${this.cameFrom}`;
      } else {
        window.location.href = this.fullStoryURL;
      }
      return true;
    },
    getViewButtonLabel() {
      if (this.showFullStoryCallToAction) {
        return this.fullViewButtonLabel;
      }

      return this.expandButtonLabel;
    },
    clickHandlerEngagement(action: string) {
      const contentPath = new URL(this.post?.card_data['card-link']).pathname;
      const { fullViewButtonLabel, post, index } = this;
      const user = post.author_id || post.author;
      segmentAnalytics.choose({
        path: contentPath,
        post: {
          id: post.id,
          title: post.title,
          topic: {
            ids: (post.topics || []).map((t: {id: string}) => t.id),
            names: (post.topics || []).map((t: {title: string}) => t.title),
          },
          user: {
            id: user.id,
            username: user.username,
          },
          type: post.type,
          pinned: post.isPinned,
        },
        context: {
          module: post.sponsored ? 'NLM_Sponsored_Story' : 'NLM_Community_Story',
          path: window.location.pathname,
          card: {
            cta: {
              text: fullViewButtonLabel,
            },
            index: index as number,
          },
          view: 'topic_feed',
        },
        target: {
          name: action,
          type: action === 'Follow' ? 'user' : 'icon',
        },
      });
    },
    clickHandler(currentPath: string) {
      const { fullViewButtonLabel, post, index } = this;
      const contentPath = getStoryUrl({ post: this.post });
      const user = post.author_id || post.author;
      segmentAnalytics.choose({
        path: contentPath,
        post: {
          id: post.id,
          title: post.title,
          topic: {
            ids: (post.topics || []).map((t: {id: string}) => t.id),
            names: (post.topics || []).map((t: {title: string}) => t.title),
          },
          user: {
            id: user.id,
            username: user.username,
          },
          type: post.type,
          pinned: post.isPinned,
        },
        context: {
          module: post.sponsored ? 'NLM_Sponsored_Story' : 'NLM_Community_Story',
          path: currentPath,
          card: {
            cta: {
              text: fullViewButtonLabel,
            },
            index: index as number,
          },
          view: 'topic_feed',
        },
        target: {
          name: 'module_cta',
          type: 'button',
        },
      });
    },
  },
  mounted() {
    const articleRef = this.$refs.articleRef as HTMLElement;
    articleRef.querySelector('.card-share-link')?.addEventListener('click', () => this.clickHandlerEngagement('share'));
    articleRef.querySelector('.post-comments-button')?.addEventListener('click', () => this.clickHandlerEngagement('comment'));
    articleRef.querySelector('.post-hearts-button')?.addEventListener('click', () => this.clickHandlerEngagement('heart'));
    articleRef.querySelector('.post-bookmark-button')?.addEventListener('click', () => this.clickHandlerEngagement('Save'));
    articleRef.querySelector('.author-info__followBtn')?.addEventListener('click', () => this.clickHandlerEngagement('Follow'));
  },
});
