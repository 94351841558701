
import { get } from 'lodash';
import { isSet, isObject, genOpenLinkCallback } from '@/data/helpers';
import BackgroundImage from '@/components/LazyImage/backgroundImage';

export default {
  name: 'CardAuthorAvatar',
  props: ['post', 'overrideAuthor', 'size', 'gaCategory'],
  components: { BackgroundImage },
  computed: {
    // normalize the post card data
    card_data() {
      return this.post.card_data || {};
    },
    avatar() {
      if (this.post.author_avatar) {
        let [, match] = this.post.author_avatar.match(/url\(['"](.+)['"]\)/);
        return match;
      }
      return get(this.post, 'author.profile.avatar_thumbnail', get(this.post, 'author_id.profile.avatar_thumbnail', get(this.author, 'avatar')));
    },
    // normalize author data
    author() {
      // allow this to be passed in
      if (isSet(this.overrideAuthor) && isObject(this.overrideAuthor)) {
        return this.overrideAuthor;
      }

      // build from voices card data
      if (isSet(this.post.author)) {
        const author = this.post.author;
        return {
          url: `/u/${author.username}/`,
          avatar: `<div class="avatar_circle avatar " style="background-image: url('${author.profile.avatar_thumbnail}'); display:block;"></div>`,
          name: author.display_name,
          role: author.profile.role,
          byline: `@${author.username}`,
          byline_url: `/u/${author.username}/`,
        };
      }

      const authorUrl = this.card_data['card-author-url'];
      const isUrl = authorUrl.includes('http');

      return {
        url: isUrl ? new URL(authorUrl).pathname : authorUrl,
        avatar: this.card_data['card-byline-avatar'],
        name: this.card_data['card-byline-author'],
        byline: this.card_data['card-author-byline'],
        byline_url: this.card_data['card-author-byline-url'],
        role: this.card_data['card-author-roles'][0],
      };
    },
    // the class associated with the role of the author
    roleClass() {
      let role = 'subscriber';

      // calculate the role from the available data
      switch (this.author.role) {
      // contributors
      case 'contributor': role = 'contributor'; break;

      // partner types
      case 'partner':
      case 'organization': role = 'partner'; break;

      // staff
      case 'administrator':
      case 'author':
      case 'editor':
      case 'staff':
      case 'freelancer': role = 'staff'; break;

      // unknown
      default: role = `${role}`; break;
      }

      return role;
    },

    mySize() {
      return this.size === '60px' ? 'big' : 'small';
    },

    wrapperClass() {
      return `${this.mySize}-avatar ${this.roleClass}`;
    },

    linkTitle() {
      return `Visit ${this.author.name}'s profile`;
    },
  },

  // intercept clicks on sub parts of the cards. used to fire different GA events
  methods: {
    // author links
    triggerAuthorLink(e, navigate) {
      this.$store.commit('triggerCardAuthorLink', {
        post: this.post,
        options: this.options,
        gaCategory: this.gaCategory,
        callback: () => navigate(e),
      });
    },
  },
};
