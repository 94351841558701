
import $ from 'jquery';
import _ from 'lodash';
import { staticPath } from '@/data/helpers';
import { getConditionsList, getCollections } from '@/data/api/amplify_data';
import { filter } from '~/src/libs/segment-analytics';
import CollectionItem from './CollectionItem';

export default {
  name: 'FindSupport',
  components: { CollectionItem },
  data() {
      return {
          selected: '',
          isMobile: false,
          running: false,
          conditions: [],
          collections: [],
      }
  },
  async mounted() {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    this.isMobile = mediaQuery.matches;

    await getConditionsList()
      .then((conditions) => {
        this.conditions = conditions;

        const recent = _.find(conditions, condition => condition.name === "Default");
        if (recent) {
          this.selected = recent.id;
        }
      })

    await getCollections(_.map(this.conditions, condition => condition.id ))
      .then((collections) => {
        this.collections = collections;
      })
      // in off chance filters list is small and doesn't need scrolling
    const filterContainer = document.querySelector('#filters-scroll');

    if (filterContainer && filterContainer.offsetWidth >= filterContainer.scrollWidth) {
      const rightScroll = document.querySelector('#right-scroll');
      rightScroll.style.display = 'none';
    }

  },
  computed: {
    filteredCollections() {
      return _.filter(this.collections, collection => collection.conditionId === this.selected).sort((a, b) => a.order - b.order)
    },
    sortedConditions() {
      return this.conditions.sort((a, b) => a.order - b.order);
    },
    filteredCondition() {
      const condition = this.conditions.filter(x => x.id === this.selected);
      return condition[0].name;
    }
  },
  methods: {
    staticPath,
    filterScroll(dir) {
      const filter = document.querySelector('#filters-scroll');
      if (dir === 'right') {
        filter.scrollLeft += 200;
      } else if (dir === 'left') {
        filter.scrollLeft -= 200;
      }
    },
    debounceFilterScroll: _.debounce(function() { this.newScroll('filters'); }, 100),
    collectionScroll(dir) {
      const container = document.querySelector('#collections-scroll');
      if (dir === 'right') {
        container.scrollLeft += 100;
      } else if (dir === 'left') {
        container.scrollLeft -= 100;
      }
    },
    debounceCollectionScroll: _.debounce(function() { this.newScroll('collections'); }, 100),
    newScroll(type) {
      // mobile scrolling is jumpy so a lot of intricacies to get it smooth, just guess and check
        let container, rightScroll, leftScroll;
        if (type === 'filters') {
          container = document.querySelector('#filters-scroll');
          rightScroll = document.querySelector('#right-scroll');
          leftScroll = document.querySelector('#left-scroll');
        } else if (type === 'collections') {
          container = document.querySelector('#collections-scroll');
          rightScroll = document.querySelector('#next-collection');
          leftScroll = document.querySelector('#previous-collection');
        }

        if (this.running) {
          return;
        }

        this.running = true;

        if (!this.isMobile) {
          container.style.paddingRight = '25px';
          rightScroll.style.display = 'flex';
        }
          leftScroll.style.display = 'flex';
          container.style.paddingLeft = '0px';


        if (container.scrollLeft === 0) {
          leftScroll.style.display = 'none';
          container.style.paddingLeft = '10px';
        } else if (container.scrollLeft + container.offsetWidth + 35 >= container.scrollWidth) {
          rightScroll.style.display = 'none';
        } else if (this.isMobile) {
          rightScroll.style.display = 'flex';
        }

        this.running = false;
    },
  }
}
