import AuthorController from '@/userExperienceRedesign/controllers/author';
import { AuthorI } from '@/userExperienceRedesign/types/author';
import { PostI } from '@/userExperienceRedesign/modules/CommunityHighlights/postHighlight';
// using require to avoid TS untyped errors
const { sanitizeText, cleanHTML } = require('@/data/card-helpers');


export default class CommunityHighlightsController {
  protected data?: PostI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    const author = new AuthorController(post);

    this.data = {
      post,
      author: author.value as AuthorI,
      avatarGroup: CommunityHighlightsController.getAvatarGroup(post), // TODO
      category: { // TODO: restore once we can set the bg color, and logic to render for curated streams only is added
        label: undefined, // post.communityTopic.title, // TODO
        color: undefined, // '#FEE4FF', // TODO
      },
      contentPreview: CommunityHighlightsController.sanitizeContent(cleanHTML(post.body), post.topics, post.mentions, post.embeds),
      // expandButtonLabel: 'See More',
      // fullViewButtonLabel: 'Join the Conversation',
      communitySize: post.action_counts.comments,
      image: CommunityHighlightsController.getPostImage(post),
      title: CommunityHighlightsController.sanitizeContent(post.title, post.topics, post.mentions, post.embeds),
      url: `/content/${post.id}`, // NOTE: Not sure if this is needed anymore
    };
  }

  protected static sanitizeContent(content: string, fallback: string, mentions: string, embeds: string[]) {
    return sanitizeText({ content: cleanHTML(content), topics: fallback, mentions, embeds });
  }

  protected static getAvatarGroup(post: any): string[] {
    return post.engagement_avatars.reduce((avatars: string[], avatar:any, index: number) => {
      if (index > 0 && index < 4) avatars.push(avatar.avatar_url);
      return avatars;
    }, []);
  }

  protected static getPostImage(post: any): string | undefined {
    return post?.embeds?.images[0]?.url;
  }
}
