
import _ from 'lodash';
import { mapGetters } from 'vuex';
import NormalizeAuthor from '@/mixins/normalize-author';
import InstantFollowCta from '@/components/InstantFollowCTA';
import { isSet, relativeDateText, genOpenLinkCallback, log, getUserRoleType, staticPath, siteUrl } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';
import ExpertBadge from '@/components/expertBadge';
import GroupBadge from '@/components/groupBadge';
import CardAuthorAvatar from '../CardAuthorAvatar';

export default {
  name: 'CardByline',
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({
        humanNumber: true,
        humanNumberFirstValue: true,
        analyticsEventData: {},
      }),
    },
    gaCategory: {
      default: '',
    },
    fullCardByline: false,
    segmentData: undefined,
  },
  mixins: [NormalizeAuthor],
  components: { CardAuthorAvatar, InstantFollowCta, ExpertBadge, GroupBadge },
  computed: {
    ...mapGetters({
      user: 'user',
      getGroup: 'getGroup'
    }),
    // normalize the post card data
    card_data() {
      return this.post.card_data || {};
    },

    // normalize article data
    article() {
      let date;
      if (isSet(this.post.created_at)) {
        date = new Date(this.post.created_at);
      } else {
        date = new Date(0);
        date.setUTCSeconds(this.post.pubdate_ts);
      }

      const { dateText, when } = relativeDateText(date);

      return {
        date,
        dateText,
        when,
      };
    },

    postStatus() {
      if (this.post.status) {
        return this.post.status.type;
      }

      return '';
    },

    icon() {
      const type = this.post.type.toLowerCase();
      // since these are the only specific types we have icons for, default to story-pinned if anything else
       if (_.includes(['thought', 'question', 'story'], type)) {
         return `${type}-pinned`;
       }
       return 'story-pinned';
    },

    isRejected() {
      return this.postStatus === 'REJECTED';
    },

    isPrivate() {
      return this.postStatus === 'PRIVATE';
    },

    sentFromApp() {
      return this.post.sentFromApp;
    },

    // the class associated with the role of the author
    roleClass() {
      return getUserRoleType(this.author.role);
    },
    followed() {
      return this.$store.getters.userFollowedUser(this.post.author_id);
    },
    authorSegmentData() {
      return _.merge({}, this.segmentData, {
        context: { module: 'byline' },
        target: {
          id: (this.post.author || {}).id,
          name: (this.post.author || {}).username,
          type: 'link',
        },
      });
    },
    followAuthorSegmentData() {
      return _.merge({}, this.authorSegmentData, {
        target: {
          type: 'user',
        },
      });
    },
    isPinned() {
      return this.post.isPinned && !this.fullCardByline;
    },
    groupByline() {
      if (_.get(this.post, 'groups[0]._id') === _.get(this.getGroup, '_id')) {
        return false;
      }
      return _.get(this.post, 'groups[0].name', false);
    },
    groupUrl() {
      return `/groups/${_.get(this.post, 'groups[0].slug')}/`;
    },
    topicByline() {
      const topics = _.get(this.post, 'topics', []);
      if (this.post.topics.length === 0) return {};

      const matchedTopics = [...this.post.body.matchAll(/<topic id=\"(.+?)\"><\/topic>/g)];
      for (const [matchedTag, topicId] of matchedTopics) {
        const topic = _.find(topics, t => t._id === topicId);
        if (topic) {
          return { slug: topic.slug, name: topic.hash_tag }
        }
      }

      return {};
    },
    isSponsored() {
      const is = _.get(this.post, 'sponsored.is');
      return is && this.sponsorName;
    },
    sponsorLabel() {
      return _.get(this.post, 'sponsored.text') || 'Sponsored by';
    },
    sponsorName() {
      return _.get(this.post, 'sponsored.user.display_name', _.get(this.post, 'sponsored.name', ''));
    },
    partnerUrl() {
      const maybeUrl = _.get(this.post, 'sponsored.url');
      if (maybeUrl) {
        return maybeUrl;
      }

      if (_.get(this.post, 'sponsored.user')) {
        return `/partner/${_.get(this.post, 'sponsored.user.username')}`;
      }

      return '';
    },
    bylineUrl() {
      const url = this.author.byline_url === this.author.url ? this.author.byline_url : `/topic${this.author.byline_url}`
      return this.post.type === 'WORDPRESS_POST' ? `/topic${this.post.card_data['card-author-byline-url']}` : url;
    },
  },

  // intercept clicks on sub parts of the cards. used to fire different GA events
  methods: {
    staticPath,
    siteUrl,
    // author links
    triggerAuthorLink(e, navigate) {
      this.$store.commit('triggerCardAuthorLink', {
        post: this.post,
        options: this.options,
        gaCategory: this.gaCategory
      });

      segmentAnalytics.choose(_.merge({}, this.authorSegmentData, {
        target: {
          name: this.post.author_display_name,
        },
      }));

      navigate(e);
    },

    // category links
    triggerCategoryLink(e, navigate) {
      this.$store.commit('triggerCardCategoryLink', {
        post: this.post,
        options: this.options,
        gaCategory: this.gaCategory
      });

      segmentAnalytics.choose(this.authorSegmentData);

      navigate(e);
    },
  },
};
