import ClipboardJS from 'clipboard';
import { log } from '@/data/helpers';

export default {
  data() {
    return {
      isCopySuccess: false,
      isCopyDisabled: false,
      clipboardObjects: [],
      clipboardEnabled: null,
    };
  },
  computed: {
    clipboardSupported() {
      return process.browser && ClipboardJS.isSupported();
    },
    clipboardLinks() {
      return [this.$refs.clipboardLink];
    },
  },
  methods: {
    isCopySuccessActive(el) {
      return this.isCopySuccess && this.clipboardEnabled === el;
    },
    clipboardText(el) {
      return el.getAttribute('data-clipboard-text');
    },
    loadClipboardJS() {
      const links = this.clipboardLinks;
      if (!this.clipboardSupported || !links.length) {
        log('Failed to initialize Clipboard Link', this.$el);
        this.isCopyDisabled = true;
        return;
      }
      // load links
      links.forEach(linkElement => this.loadClipboardLink(linkElement));
    },
    loadClipboardLink(el) {
      const clipboard = new ClipboardJS(el);
      this.clipboardObjects.push(clipboard);

      clipboard.on('success', () => {
        this.clipboardEnabled = el;

        setTimeout(() => {
          // hide success message
          this.clipboardEnabled = null;
          this.isCopySuccess = false;
        }, 3000);

        // reveal success message
        this.isCopySuccess = true;
      });

      clipboard.on('error', () => {
        this.clipboardEnabled = null;
        log(`Failed to copy: ${this.clipboardText(el)}`, this.$el);
      });
    },
  },
  mounted() {
    this.loadClipboardJS();
  },
  beforeDestroy() {
    if (this.clipboardObjects.length) {
      this.clipboardObjects.forEach(clip => clip.destroy());
    }
  },
};
