
import _ from 'lodash';
import NormalizeAuthor from '@/mixins/normalize-author';
import InstantFollowCta from '@/components/InstantFollowCTA';
import { isSet, relativeDateText, genOpenLinkCallback, log, getUserRoleType, staticPath, siteUrl } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';
import CardAuthorAvatar from './CardAuthorAvatar';
import LazyImage from '@/components/LazyImage';

export default {
  name: 'CardBylineBig',
  props: ['post', 'options', 'gaCategory', 'segmentData'],
  components: { InstantFollowCta, CardAuthorAvatar, LazyImage },
  mixins: [NormalizeAuthor],
  computed: {
    // normalize the post card data
    card_data() {
      return this.post.card_data || {};
    },

    byline() {
      const author = NormalizeAuthor.computed.author.call(this);
      return this.post.type === 'WORDPRESS_POST' ? this.post.card_data['card-author-byline'] : author.byline;
    },

    bylineUrl() {
      const author = NormalizeAuthor.computed.author.call(this);
      return this.post.type === 'WORDPRESS_POST' ? `/topic${this.post.card_data['card-author-byline-url']}` : author.byline_url;
    },

    postStatus() {
      if (this.post.status) {
        return this.post.status.type;
      }

      return '';
    },

    isRejected() {
      return this.postStatus === 'REJECTED';
    },

    isPrivate() {
      return this.postStatus === 'PRIVATE';
    },

    getVisibilityMessage() {
      if (this.isPrivate) {
        return 'Only visible to you and Mighty staff';
      } else if (this.isRejected) {
        return 'Only visible to you';
      }
      return '';
    },

    getHelveticaDot() {
      if (this.isPrivate) {
        return ' • ';
      } else if (this.isRejected) {
        return ' • ';
      }
      return '';
    },

    // normalize article data
    article() {
      let date;
      if (isSet(this.post.created_at)) {
        date = new Date(this.post.created_at);
      } else {
        date = new Date(0);
        date.setUTCSeconds(this.post.pubdate_ts);
      }

      const { dateText, when } = relativeDateText(date);

      return {
        date,
        dateText,
        when,
      };
    },

    // the class associated with the role of the author
    roleClass() {
      return getUserRoleType(this.author.role);
    },

    authorSegmentData() {
      return _.merge({}, this.segmentData, {
        context: { module: 'byline' },
        target: {
          id: _.get(this.post, 'author.id'),
          name: _.get(this.post, 'author.username'),
          type: 'user',
        },
      });
    },
    isPinned() {
      return this.post.isPinned && !this.fullCardByline;
    },
    isSponsored() {
      const is = _.get(this.post, 'sponsored.is');
      return is && this.sponsorName;
    },
    sponsorLabel() {
      return _.get(this.post, 'sponsored.text') || 'Sponsored by';
    },
    sponsorName() {
      return _.get(this.post, 'sponsored.user.display_name', _.get(this.post, 'sponsored.name', ''));
    },
    partnerUrl() {
      const maybeUrl = _.get(this.post, 'sponsored.url');
      if (maybeUrl) {
        return maybeUrl;
      }

      if (_.get(this.post, 'sponsored.user')) {
        return `/partner/${_.get(this.post, 'sponsored.user.username')}`;
      }

      return '';
    },
  },

  // intercept clicks on sub parts of the cards. used to fire different GA events
  methods: {
    staticPath,
    siteUrl,
    // author links
    triggerAuthorLink(e, navigate) {
      this.$store.commit('triggerCardAuthorLink', {
        post: this.post,
        gaCategory: this.gaCategory,
        options: this.options,
      });
      segmentAnalytics.choose(_.merge({}, this.authorSegmentData, {
        target: {
          id: this.post.author.id,
          name: this.post.author.name,
          type: 'link',
        },
      }));

      navigate(e);
    },

    // category links
    triggerCategoryLink(e, navigate) {
      this.$store.commit('triggerCardCategoryLink', {
        post: this.post,
        gaCategory: this.gaCategory,
        options: this.options,
      });
      segmentAnalytics.choose(_.merge({}, this.authorSegmentData, {
        target: {
          id: this.post.topics[0].id,
          name: this.post.topics[0].title,
          type: 'link',
        },
      }));

      navigate(e);
    },
  },
};
