
// libs
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { staticPath } from '@/data/helpers';
import LazyImage from '@/components/LazyImage';

export default {
  name: 'MenuDots',
  props: ['itemId'],
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  components: {
    LazyImage
  },
  methods: {
    staticPath,
    itemMenuClick(event) {
      const buttonWrap = $(event.target.closest('.action-menu'));
      if (!buttonWrap.hasClass('focused')) {
        $('.action-menu').removeClass('focused');
        buttonWrap.addClass('focused');
        $(document).bind('click.openActionMenu', (e) => {
          if (!e.target.closest('.action-menu')) { // click outside
            buttonWrap.removeClass('focused');
            $(document).unbind('click.openAcitonMenu');
          }
        });
      } else {
        buttonWrap.removeClass('focused');
      }
    },
  },
};
