
import vue, { PropType } from 'vue';
import { AvatarPropsI } from './types';
import LazyImage from '@/components/LazyImage/index.vue';

export default vue.extend({
  name: 'Avatar',
  components: { LazyImage },
  props: {
    size: {
      type: Number as PropType<AvatarPropsI['size']>,
      default: 24,
    },
    src: {
      type: String as PropType<AvatarPropsI['src']>,
      required: true,
    },
  },
});
