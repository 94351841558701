import lock from '@/libs/lock';

/**
 * mixin to handle page locking when the current element is in an open state. used on modals mostly
 */
export default {
  // accept params from parent elements
  props: {
    open: {
      default: false,
    },
  },

  // check the state when the modal is first created
  mounted() {
    if (this.open) {
      lock.lockScroll([this.$el]);
    }
  },

  // make sure to always unlock upon closing
  beforeDestroy() {
    lock.unlockScroll([this.$el]);
  },

  // when the element is updated, we need to add the scrolling exception, if the element is present
  updated() {
    if (this.$el.tagName) {
      lock.allowScrolling(this.$el);
    } else {
      lock.removeAllowScrolling(this.$el);
    }
  },

  // look for signals from the parent, and respond when our open state changes
  watch: {
    open() {
      if (this.open) {
        lock.lockScroll([this.$el]);
      } else {
        lock.unlockScroll([this.$el]);
      }
    },
  },
};
