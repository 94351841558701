
export default {
  name: 'CardImage',
  props: ['post', 'options'],
  computed: {
    card_data() {
      return this.post.card_data || {};
    },
    isVideo() {
      return this.post.type === 'WORDPRESS_VIDEO';
    },
    article() {
      const imageData = {
        image: this.isVideo ? this.card_data['card-image-main-story'] || '' : this.card_data['card-image'] || '',
        imageMainStory: this.card_data['card-image-main-story'] || '',
      };

      imageData.image = imageData.image.replace('<img ', '<img loading="lazy" ');
      imageData.imageMainStory = imageData.imageMainStory.replace('<img ', '<img loading="lazy" ');
      return imageData;
    },
    featuredImageUrl() {
      return this.article.imageMainStory.length ? this.article.imageMainStory : this.article.image;
    },
  },
};
