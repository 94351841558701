
/* eslint no-underscore-dangle:0 */
import { mapGetters } from 'vuex';
import $ from 'jquery';
import _ from 'lodash';
import { log, doesUserOwnPost, isSet, isObject, isArray, getItemPermalink, genAnalyticsUtmData, sendSegmentEvent } from '@/data/helpers';

// sibling components
import ActionMenu from '@/components/ActionMenu';
import MenuDots from '@/components/ActionMenu/MenuDots';
import MenuPane from '@/components/ActionMenu/MenuPane';
import FormButton from '@/components/FormButton';
import embedCardSnippet from '@/embed/card/snippet.html';
import clipboardLink from '@/mixins/clipboard-link';
import { getSegmentGroupCard, getSegmentGroupFeedView, getSegmentGroupTarget } from '@/libs/events';
import { transformHashTags, transformMentions, cleanHTML } from '@/data/card-helpers';
import { renderAllEmbeds } from '@/data/submission-helpers';
import userApi from '@/data/api/user';

export default {
  name: 'FeedCardActionMenu',
  props: {
    cardIndex: {},
    item: {
      type: Object,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    segmentData: {
      type: Object,
      default: () => ({
        context: {
          card: {},
          view: 'post_feed',
        },
      }),
    },
  },
  components: { ActionMenu, FormButton },
  mixins: [clipboardLink],

  data() {
    return {
      needsConfirmDelete: false,
      removing: false,
      isFollowSuccess: false,
      isUnfollowSuccess: false,
      isFlagHighAlertSuccess: false,
      beenFlagged: false,
    };
  },
  computed: {
    ...mapGetters({
      menuOpenItemId: 'menuOpenFor',
      user: 'user',
      getCurrentFullPostSegmentData: 'getCurrentFullPostSegmentData',
      getTopic: 'getTopic',
      getGroup: 'getGroup',
      getMyBlockedUsers: 'getMyBlockedUsers',
      isPinnedFeaturedContent: 'isPinnedFeaturedContent',
    }),

    notLoggedIn() {
      return _.isNil(this.user._id);
    },

    // the id of this item
    itemId() {
      if (isObject(this.item) && (isSet(this.item._id) || isSet(this.item.id))) {
        if (isSet(this.item._id)) {
          return this.item._id;
        }
        return this.item.id;
      }
      return this.$vId;
    },

    // the link for this item
    itemLink() {
      const utmDefaultData = genAnalyticsUtmData({
        utm_source: 'action_menu',
        utm_campaign: `${this.options.utmCampaignPrefix}.action_menu`,
        utm_medium: 'link',
      });
      const link = getItemPermalink(this.item);

      return utmDefaultData(link);
    },

    itemEmbed() {
      const compiled = _.template(embedCardSnippet);
      return compiled({
        date: new Date().getTime(),
        permalink: getItemPermalink(this.item),
        title: this.item._normalized_title,
        baseUrl: process.env.SITE_BASE_URL,
      });
    },

    clipboardLinks() {
      return [this.$refs.clipboardLink, this.$refs.clipboardEmbed, this.$refs.storyLink];
    },

    // does this user own this item
    ownsItem() {
      return doesUserOwnPost(this.user, this.item);
    },

    // cant block user if it's a staff account
    staffItem() {
      return _.get(this.item, 'author_id.profile.role') === 'staff';
    },

    isStaff() {
      return ['administrator', 'editor', 'author'].includes(this.user.role_slug);
    },

    isGroupLeader() {
      return _.get(this.getGroup, 'leaders', []).indexOf(_.get(this.user, '_id')) !== -1;
    },

    allowBanFromGroup() {
      return (this.isGroupLeader && _.get(this.item, 'author._id') !== _.get(this.user, '_id'));
    },

    isGroupPrompt() {
      return _.get(this.getGroup, 'prompt') === this.item.id;
    },

    hasSponsorship() {
      return _.get(this.item, 'sponsored.is');
    },
    userIsBlocked() {
      return this.getMyBlockedUsers.includes(_.get(this.item, 'author_id._id'));
    },
    canPinPost() {
      return this.isStaff && this.getTopic._id;
    },
    canGroupPrompt() {
      return this.isGroupLeader && !this.isGroupPrompt && this.getGroup._id;
    },
    cantGroupPrompt() {
      return this.isGroupLeader && this.isGroupPrompt && this.getGroup._id;
    },
    canModerateItem() {
      if (!this.user) {
        return false;
      }

      return isSet(this.user) && isSet(this.user.profile) && this.user.profile.role === 'staff';
    },

    isSubscribed() {
      return this.item.subscribed;
    },

    isShowingMessage() {
      if (this.isCopySuccess) {
        return true;
      }
      if (this.needsConfirmDelete) {
        return true;
      }
      if (this.isFollowSuccess || this.isUnfollowSuccess || this.isFlagHighAlertSuccess) {
        return true;
      }
      return false;
    },

    itemRejected() {
      return this.item.status.type === 'REJECTED';
    },

    /*
    // are we currently reporting this item?
    isCurrentlyReporting() {
      return this.itemId === this.currentlyReporting;
    },

    // was the item recently reported?
    wasReported() {
      return this.itemId === this.lastReportedCommentId;
    },
    */

    isPinned() {
      return _.get(this.getTopic, 'pinned.content', []).includes(this.item.id);
    },
    postTypeString() {
      return this.isPoll ? 'Poll' : 'Post';
    },
    isPost() {
      return this.item.type === 'THOUGHT' || this.item.type === 'QUESTION';
    },
    isPoll() {
      return this.item.type === 'POLL';
    },
    canToggleEarlyResults() {
      return this.isPoll && _.get(this.item, 'canToggleEarlyResults', false);
    },
    showEarlyResults() {
      return this.isPoll && _.get(this.item, 'showEarlyResults', false);
    },
    storyCard() {
      return this.item.type === 'WORDPRESS_POST' || this.item.type === 'WORDPRESS_VIDEO';
    },
    currentlyFeatured() {
      return this.isPinnedFeaturedContent(this.item._id);
    },
    pinContentText() {
      if (this.currentlyFeatured) return "Unpin from What I'm Featuring";
      return "Pin to What I'm Featuring";
    },
  },

  watch: {
    menuOpenCommentId() {
      if (this.menuOpenItemId === this.itemId) {
        this.$refs.menu.focus();
      }
    },
    /*
    // watch for changes in the reported comment indicator. this is what shows the original message, after the fade of the message is complete
    lastReportedCommentId() {
      // only do this if it was THIS comment that was reported
      if (this.lastReportedCommentId === this.comment.id) {
        const me = this;
        setTimeout(() => {
          me.$store.commit('setLastReported', 0);
        }, 2100);
      }
    },
    */
  },

  // actions from interactions
  methods: {
    /*
    // clicked the report item menu item
    showReportModal() {
      this.$store.commit('toggleReportItemModal', this.item);
    },
    */

    // clicked the delete menu item
    confirmDeleteItem(e) {
      this.needsConfirmDelete = true;
      e.target.closest('.item-menu').focus();
    },

    async openEditpost() {
      const { _id, title: contentTitle, body: contentBody, topics, mentions, embeds } = this.item;
      const title = cleanHTML(transformHashTags(contentTitle || '', topics || [], true));
      const transformBodyHashtags = transformHashTags(contentBody || '', topics || [], true);
      let body = transformMentions(transformBodyHashtags, mentions || [],  true);
      if (isObject(embeds) && isSet(embeds.links) && isArray(embeds.links)) {
        _.forEach(embeds.links, link => {
          const find = `<link id="${link.id}"></link>`;
          const text = isSet(link.originalText) ? link.originalText : '';
          body = body.replace(find, text);
        })
      }
      body = cleanHTML(body);
      const data = { type: 'THOUGHT', title, body };
      await this.$store.commit('setLastPostArgs', data);
      await this.$store.dispatch('openModal', { 
        id: 'new-post-modal', 
        args: { editMode: true, postId: _id } });
    },

    // when cancel is hit on close delete
    closeConfirmDeleteItem() {
      this.needsConfirmDelete = false;
    },

    // when delete is hit on delete form
    deleteItem() {
      // allow for animation to complete before completely removing
      const el = this.$refs.element.closest('.voices-card');
      // TODO: may be modal...need a way to still get
      if (el) {
        el.classList.add('removing');
      }
      // const postEl = e.target.closest('.voices-card');
      // if (postEl) {
      //   postEl.classList.add('removing');
      // }
      this.removing = true;
      setTimeout(() => {
        this.needsConfirmDelete = false;
        this.removing = false;
        // remove AFTER fade animation
        this.$store.dispatch('removePostFromHomeFeed', this.itemId);
        this.$store.dispatch('removePostFromProfileFeed', this.itemId);
        this.$store.dispatch('removePostFromTopicFeed', this.itemId);
        // deletes post from server
        this.$store.dispatch('deletePost', { postId: this.itemId });
      }, 500);
    },

    disableClick(e) {
      e.preventDefault();
      e.stopPropagation();
    },

    flagHighAlert(e) {
      if (!this.user.id) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }
      const itemMenu = e.target.closest('.item-menu');
      this.$store.dispatch('reportHighAlertPost', { contentId: this.item.id })
        .then(() => {
          this.isFlagHighAlertSuccess = true;
          this.beenFlagged = true;
          setTimeout(() => {
            $(this.$refs.didHighAlert).fadeOut({
              duration: 500,
              complete: () => {
                itemMenu.blur();
                this.isFlagHighAlertSuccess = false;
              },
            });
          }, 2000);
        });
    },

    openReportModal() {
      if (!this.user.id) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }
      this.$store.dispatch('openModal', {
        id: 'report-post',
        args: { content_id: this.item._id, cardIndex: this.cardIndex, post: this.item, segmentData: this.segmentData },
      });
    },

    toggleEarlyResults() {
      this.$emit('update');
    },

    pinPost() {
      this.$store.dispatch('pinPost', { post: this.item, topicId: this.getTopic._id })
        .then((res) => {
          if (res.data.raw) {
            window.location.href = `/topic/${this.getTopic.slug}/`;
          }
        });
    },

    unpinPost() {
      this.$store.dispatch('unpinPost', { post: this.item, topicId: this.getTopic._id })
        .then((res) => {
          if (res.data.raw) {
            window.location.href = `/topic/${this.getTopic.slug}/`;
          }
        });
    },

    // this is to feature on users profile
    async pinContent() {
      if (this.currentlyFeatured) {
        await this.$store.dispatch('removeMyFeaturedContent', { userId: this.user._id, itemId: this.itemId });
        return;
      }
      await this.$store.dispatch('pinToMyFeaturedContent', { userId: this.user._id, itemId: this.item._id, content: this.item });
    },
    

    trackFollow(follow) {
      this.segmentTracking(follow ? 'Follow Post' : 'Unfollow Post');
    },

    onCopyLink() {
      this.segmentTracking('Copy Link');
    },

    onCopyEmbed() {
      this.segmentTracking('Copy Link');
    },

    segmentTracking(eventName) {
      const post = this.item;
      let cardIndex = this.cardIndex;
      let { context: { card } } = this.segmentData;
      const viewName = this.segmentData.context.view.name;
      const fullPostSegmentData = this.getCurrentFullPostSegmentData;
      if (fullPostSegmentData && fullPostSegmentData.context.card && !card.body) {
        card = fullPostSegmentData.context.card;
        card.type = 'full';
        cardIndex = fullPostSegmentData.cardIndex;
      }

      const properties = {
        context: {
          module: 'Action Menu',
          card: getSegmentGroupCard({ cardBody: card.body, cardIndex, cardType: card.type, post }),
          section: 'Action Menu',
          view: getSegmentGroupFeedView({ post, viewName }),
        },
        target: getSegmentGroupTarget({ post }),
        userId: this.user._id || (fullPostSegmentData ? fullPostSegmentData.userId : ''),
        sessionId: this.user.sessionId,
      };
      
      this.$segment.track(eventName, properties);
      sendSegmentEvent(eventName, properties, null);
    },

    followItem(e) {
      if (!this.user.id) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }
      const itemMenu = e.target.closest('.item-menu');
      itemMenu.focus();
      this.$store.dispatch('followPost', {
        itemId: this.item._id,
        initiator: 'action-menu',
        after: () => {
          this.isFollowSuccess = true;
          this.trackFollow(true);
          setTimeout(() => {
            $(this.$refs.hasFollowed).fadeOut({
              duration: 500,
              complete: () => {
                itemMenu.blur();
                this.isFollowSuccess = false;
              },
            });
          }, 2000);
        },
      });
    },

    unfollowItem(e) {
      const itemMenu = e.target.closest('.item-menu');
      itemMenu.focus();
      this.$store.dispatch('unfollowPost', {
        itemId: this.item._id,
        initiator: 'action-menu',
        after: () => {
          this.isUnfollowSuccess = true;
          this.trackFollow(false);
          setTimeout(() => {
            $(this.$refs.hasUnfollowed).fadeOut({
              duration: 500,
              complete: () => {
                itemMenu.blur();
                this.isUnfollowSuccess = false;
              },
            });
          }, 2000);
        },
      });
    },

    addSponsorship() {
      this.$store.dispatch('openModal', {
        id: 'add-sponsorship',
        args: { content_id: this.item._id },
      });
    },

    setGroupPrompt() {
      this.$store.dispatch('openModal', {
        id: 'group-prompt',
        args: { content_id: this.item._id, group_id: this.getGroup._id, action: 'set' },
      });
    },

    removeGroupPrompt() {
      this.$store.dispatch('openModal', {
        id: 'group-prompt',
        args: { group_id: this.getGroup._id, action: 'remove' },
      });
    },

    banUser() {
      this.$store.dispatch('openModal', {
        id: 'ban-member',
        args: { user: this.item.author },
      });
    },

    removeSponsorship() {
      this.$nodeAxios.post(`/api/v1/content/remove_sponsor/${this.item._id}`)
        .then((response) => {
          if (response.data) {
            location.reload();
          }
        });
    },

    addUserBlock() {
      let blocked_user_id;
      if (isObject(this.item.author_id) && isSet(this.item.author_id._id)) {
        blocked_user_id = this.item.author_id._id;
      } else if (isSet(this.item.author_id)) {
        blocked_user_id = this.item.author_id;
      }
      this.$nodeAxios.put('/api/v1/user/block', {
        blocked_user_id,
      }).then((response) => {
        if (response.data.success) {
          location.reload();
        }
      });
    },

    removeUserBlock() {
      let blocked_user_id;
      if (isObject(this.item.author_id) && isSet(this.item.author_id._id)) {
        blocked_user_id = this.item.author_id._id;
      } else if (isSet(this.item.author_id)) {
        blocked_user_id = this.item.author_id;
      }
      this.$nodeAxios.put('/api/v1/user/unblock', {
        blocked_user_id,
      }).then((response) => {
        if (response.data.success) {
          location.reload();
        }
      });
    },
  },
};
