
import $ from 'jquery';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { isSet, humanNumberFormatWithOptions, getItemPermalink, siteUrl, genOpenLinkCallback, staticPath } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';

export default {
  name: 'PostCommentsButton',
  props: ['post', 'options', 'mode', 'url', 'gaCategory', 'segmentData'],
  computed: {
    ...mapGetters({
      user: 'user',
      getMygroups: 'getMygroups',
    }),
    card_data() {
      return this.post.card_data || {};
    },
    commentsCount() {
      return _.get(this.post, 'action_counts.comments', 0);
    },
    comments_url() {
      if (isSet(this.url) && this.url.length) {
        return this.url;
      }
      if (isSet(this.post.commentUrl) && this.post.commentUrl.length) {
        return siteUrl(this.post.commentUrl);
      }
      if (isSet(this.post.card_data) && isSet(this.post.card_data['card-comment-url'])) {
        return siteUrl(this.post.card_data['card-comment-url']);
      }
      const relativeToPage = true;
      return getItemPermalink(this.post, relativeToPage);
    },
    iconClass() {
      return this.mode === 'question' ? 'comment-question' : 'comment-thought';
    },
    // is the user logged in?
    loggedIn() {
      return this.user && this.user.id;
    },
    // if the user is not part of the Group and not Staff
    needsToJoin() {
      return (_.map(_.filter(this.getMygroups, g => g.role !== 'MEMBER_DEFERRED_JOIN'), '_id').indexOf(_.get(this.post, 'groups[0]._id')) === -1) &&
        (_.get(this.post, 'groups.length', 0) !== 0) && (_.get(this.user, 'profile.role', '') !== 'staff');
    },
  },
  methods: {
    staticPath,
    humanNumberFormatWithOptions,
    openContent(e) {
      let callback = null;
      if (this.mode === 'story') {
        callback = genOpenLinkCallback(e.currentTarget, e);
      } else {
        this.$store.dispatch('updateCurrentFullPost', { data: this.post, options: { scrollToComments: true } });
        this.$store.dispatch('openModal', { id: 'view-full-post' });
      }
      this.$store.dispatch('voiceEvent', {
        category: this.gaCategory.includes('conversation_module') ? `${this.gaCategory}.comment` : this.gaCategory,
        action: 'choose.comment',
        label: this.post.id,
        callback,
      });

      segmentAnalytics.choose(this.segmentData);
    },
    triggerCommentsButton(ev) {
      if (!this.loggedIn) {
        this.$store.dispatch('notLoggedInAction');
      } else if (this.needsToJoin) {
        const callback = () => { this.openContent(ev); };

        const { section, view } = this.segmentData.context;
        let contextSection = section;
        if (view === 'topic_feed' && section === '') {
          contextSection = 'hubs_group';
        }

        this.$store.dispatch('openModal', {
          id: 'join-to-action',
          args: { callback, action: 'comment', group: _.get(this.post, 'groups[0]'), page: view, section: contextSection},
        });
      } else {
        this.openContent(ev);
      }
    },
  },
};
