import { StreamItem } from '@/types/streamItem';
import { PropType } from 'vue';

export interface NewsletterPropsI {
  index?: number;
  post?: StreamItem;
  signUpUrl: string;
  heading?: string;
  bodyCopy: string;
  ctaText: string;
  imageSrc?: string,
}

export interface NewsletterProxyPropsI {
  newsletter: NewsletterPropsI;
  newsletterType: 'NEWSLETTER_ADVOCATES' | 'NEWSLETTER_COLLABORATIONS' | 'NEWSLETTER_PULLQUOTE' | 'NEWSLETTER_BG_IMAGE' | 'NEWSLETTER_BASIC';
}

export const newsLetterModuleProps = {
  index: Number as PropType<NewsletterPropsI['index']>,
  /**
   * Post object associated with the newsletter
   */
  post: Object as PropType<NewsletterPropsI['post']>,
  /**
  * A string that will be used to link user to sign up page
  */
  signUpUrl: {
    type: String as PropType<NewsletterPropsI['signUpUrl']>,
    required: true,
  },
  /**
  * A string used as the newsletter heading
  */
  heading: String as PropType<NewsletterPropsI['heading']>,
  /**
  * A string that will be used for the newsletter body text
  */
  bodyCopy: String as PropType<NewsletterPropsI['bodyCopy']>,
  /**
  * A string that will fill the cta button
  */
  ctaText: String as PropType<NewsletterPropsI['ctaText']>,
  /**
   * Different modules will use this prop differently
   * basic: will show abvove text
   * the mighty: will show as background
   */
  imageSrc: String as PropType<NewsletterPropsI['imageSrc']>,
};

