
/* eslint no-console:0 */
// libs
import { mapGetters } from 'vuex';
import FieldLabel from '../FieldLabel';
import InstructionText from '../InstructionText';
import HelperMark from '../HelperMark';
import CheckBox from '../CheckBox';

export default {
  name: 'CheckBoxes',
  components: { FieldLabel, InstructionText, HelperMark, CheckBox },
  props: ['args'],
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    ...mapGetters({
      lastEditedField: 'getLastEditedField',
    }),
    checkedCnt() {
      let cnt = 0;
      this.args.options.forEach((opt) => {
        cnt += (opt.checked ? 1 : 0);
      });
      return cnt;
    },
    isLastEdited() {
      return this.$vId === this.lastEditedField;
    },
  },
  watch: {
    checkedCnt() {
      const checked = [];
      this.args.options.forEach((opt) => {
        if (typeof opt.checked !== 'undefined' && opt.checked !== false) {
          checked.push(opt.value);
        }
      });
      this.$emit('input', checked);
    },
  },
  methods: {
    updateParent(value) {
      this.$emit('input', value);
    },
    gotFocused() {
      this.focused = true;
    },
    gotBlurred() {
      this.focused = false;
    },
    updateLastEdited() {
      this.$store.commit('setLastEditedField', this.$vId);
    },
  },
  beforeMount() {
    this.args.options.forEach((option) => {
      // eslint-disable-next-line no-param-reassign
      option.checked = false;
    });
  },
};
