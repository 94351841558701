
import vue from 'vue';
import LikeIcon from '@/userExperienceRedesign/components/Icon/likeIcon.vue';

export default vue.extend({
  name: 'PostHeartsButton',
  components: { LikeIcon },
  props: {
    /** Asks if like button is on dark background  */
    isOnDarkBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updateTextColor() {
      if (this.isOnDarkBg) {
        return 'socialButton--onDark';
      }
      return '';
    },
  },
});
