
import { mapGetters } from 'vuex';
import { isSet, doesUserOwnPost } from '@/data/helpers';
import StoryCardFooter from '@/feed/FeedCard/CardFooter';
import FeedCardActionMenu from '@/feed/FeedCard/FeedCardActionMenu';
import CardComment from "@/feed/CardComment";
import ContentCard from '../ContentCard';
import CardImage from './CardImage';
import CardByline from '../FeedCard/CardBylineBig';
import CardBylineSmall from '../FeedCard/CardBylineSmall';
import StoryCardContent from './StoryCardContent';

export default {
  name: 'StoryCard',
  components: { ContentCard, CardImage, CardByline, CardBylineSmall, StoryCardContent, StoryCardFooter, FeedCardActionMenu, CardComment },

  // passed in properties
  props: {
    feedName: {
      default: '',
    },
    size: {
      default: '60px',
    },
    post: {
      type: Object,
      required: true,
    },
    options: {
      humanNumber: true,
      humanNumberFirstValue: true,
      analyticsEventData: {},
      utmCampaignPrefix: '',
    },
    gaCategory: {
      default: '',
    },
    cameFrom: {
      default: '',
    },
    segmentData: undefined,
  },

  // internal data for this element
  data() {
    return {
    };
  },

  // create some virtual properties from other data bits
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    isAuthor() {
      return doesUserOwnPost(this.user, this.post);
    },
    card_data() {
      return this.post.card_data || {};
    },
    article() {
      return {
        image: isSet(this.card_data['card-image']) ? this.card_data['card-image'] : '',
        imageMainStory: isSet(this.card_data['card-image-main-story']) ? this.card_data['card-image-main-story'] : '',
      };
    },
    hasImage() {
      return this.article.image.length || this.article.imageMainStory.length;
    },
    // On a User's profile page, displaying the user's comment below the Post Card (Only for ProfileStories component)
    hasCommentToShow() {
      return this.post.comment && this.post.comment._id;
    },
    smallAvatar() {
      return this.size === '40px';
    },
  },
};
