// get the style attribute of an element
function eleStyle(ele) {
  return ele.attributes.style && ele.attributes.style.value
    ? ele.attributes.style.value
    : '';
}

/**
 * this mixin adds a property to the element which tells whether the current screensize is large or small, based on our mobile/desktop breakpoint
 */
export default {
  // data needed for this feature
  data() {
    return {
      contentHeight: 0,
      contentOpen: false,
      openSwitchBtn: false,
    };
  },

  // properties that require calculation
  computed: {
    maxCollapsedHeight() {
      // return this.maxHeight || 100;
      return 120;
    },
    tooLong() {
      return this.contentHeight > this.maxCollapsedHeight;
    },
  },

  // when the element is created, initially measure the content
  mounted() {
    // watch the content height of the content block. this will trigger showing the see-more and see-less links
    const me = this;
    const updateContentHeight = () => {
      me.contentHeight = me.$refs.content ? me.$refs.content.scrollHeight : 0;
    };
    setTimeout(updateContentHeight, 100);
    window.addEventListener('resize', updateContentHeight);
    window.addEventListener('orientationchange', updateContentHeight);
  },

  // public methods exposed to the element
  methods: {
    // correct animation. not pure css... but it is accurate and still fast
    openContent() {
      this.contentOpen = true;
      // toggle button switch after animation should be finished
      setTimeout(() => { this.openSwitchBtn = true; }, 1000);

      // proper animation
      const height = this.$refs.content.scrollHeight;
      let st = eleStyle(this.$refs.content);
      st = st.replace(/max-height\s*:[^;]*;/g, '');
      st = `${st} max-height:${height}px;`;
      this.$refs.content.setAttribute('style', st);
      this.$refs.content.classList.remove('fade');
    },
    closeContent() {
      this.contentOpen = false;
      // toggle button switch after animation should be finished
      setTimeout(() => { this.openSwitchBtn = false; }, 1000);

      // proper animation
      let st = eleStyle(this.$refs.content);
      st = st.replace(/max-height\s*:[^;]*;?/g, '');
      this.$refs.content.setAttribute('style', st);
      this.$refs.content.classList.add('fade');
    },
  },
};
