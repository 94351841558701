import { NewsletterProxyPropsI } from '../modules/Newsletter/types';

export default class NewsletterController {
  protected data?: NewsletterProxyPropsI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    this.data = {
      newsletter: {
        post,
        signUpUrl: post.newsletter.cta_link,
        heading: post._normalized_title,
        bodyCopy: post._normalized_body,
        ctaText: post.newsletter.cta_text,
        imageSrc: post.newsletter.image_src,
      },
      newsletterType: post.newsletter.type,
    };
  }
}
