import { EventsPropsI } from '../modules/Events/types';

export default class EventController {
  protected data?: EventsPropsI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    this.data = {
      eventInfo: {
        post,
        imageSrc: post.event.image_src,
        host: post.event.host,
        hostTitle: post.event.host_title,
        headline: post._normalized_title,
        eventShortDescription: post._normalized_body,
        eventDate: post.event.event_time,
        eventUrl: post.event.rsvp_link,
      },
      withHostInfo: Boolean(post.event.host),
      ctaText: 'Save Your Spot',
      inPerson: post.event.in_person,
      category: {
        label: undefined, // post.communityTopic.title, // TODO
        color: undefined, // '#FEE4FF', // TODO
      },
    };
  }
}
