
// libs
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { getSegmentGroupCard, getSegmentGroupFeedView, getSegmentGroupTarget } from '@/libs/events';
import { staticPath, sendSegmentEvent } from '@/data/helpers';
import BlockModal from '../../components/BlockModal';
import MultilineTextBox from '../../components/MultilineTextBox';
import CheckBoxes from '../../components/CheckBoxes';

export default {
  name: 'ReportPost',
  components: { BlockModal, CheckBoxes, MultilineTextBox },
  // local props
  props: {
    id: 0,
    index: undefined,
    modalOpen: {
      default: false,
    },
    modalName: {
      default: 'report-post',
    },
    modalArgs: {},
  },
  data() {
    return {
      // open: false,
      icon: staticPath('report-post@2x.png'),
      iconWidth: 72,

      // settings for form elements, and containers for values
      formElements: {
        cannedResponses: {
          args: {
            required: true,
            post: {
              label: 'This post...',
              options: [
                { label: 'has triggering language', value: 'TRIGGERING', checked: false },
                { label: 'has offensive language', value: 'OFFENSIVE', checked: false },
                { label: 'has a triggering photo', value: 'TRIGGERING_PHOTO', checked: false },
                { label: 'has an offensive photo', value: 'OFFENSIVE_PHOTO', checked: false },
                { label: 'solicits the community', value: 'SOLITATION', checked: false },
                { label: 'other', value: 'OTHER', checked: false },
              ],
            },
            user: {
              label: 'This user...',
              options: [
                { label: 'needs prevention and crisis resources', value: 'OTHER', checked: false },
              ],
            },
            value: [],
          },
        },
        feedback: {
          args: {
            label: 'Other Feedback',
            placeholder: 'What else should we know?',
            value: '',
          },
        },
      },
    };
  },

  // validate form fields
  validations: {
    formElements: {
      // canned response checkboxes
      cannedResponses: {
        args: {
          value: {
            required,
            minLength: minLength(1),
          },
        },
      },

      // extra feedback textarea
      feedback: {
        args: {
          value: {
            minLength: minLength(0),
            maxLength: maxLength(1024),
          },
        },
      },
    },
  },

  // load props from vuex and any other derived props
  computed: {
    ...mapGetters({
      user: 'user',
      getCurrentFullPostSegmentData: 'getCurrentFullPostSegmentData',
    }),
    open() {
      return this.modalOpen;
    },
    canSubmit() {
      return !this.$v.$invalid;
    },
    canModerate() {
      const user = this.$store.state.session.user;
      if (!user || !user.profile) {
        return false;
      }
      const role = user.profile.role;
      const staffRoles = [
        'administrator',
        'editor',
        'staff',
      ];
      return staffRoles.includes(role);
    },
  },

  watch: {
    modalOpen() {
      // reset form
      this.formElements.cannedResponses.args.value = [];
      this.formElements.feedback.args.value = '';
    },
  },

  // actions formed inside this element
  methods: {
    staticPath,
    // actually report this comment
    reportPost() {
      // actually report the comment
      this.$store.dispatch('reportPost', {
        contentId: this.modalArgs.content_id,
        reportData: {
          status: this.formElements.cannedResponses.args.value,
          feedback: this.formElements.feedback.args.value,
        },
      }).then(() => {
          this.$store.dispatch('addAlertData', { name: 'group-alert', data: { icon: '✅', message: 'Thanks for sending your feedback' } });
          this.$store.dispatch('openAlert', 'group-alert');
          setTimeout(() => {
            this.$store.dispatch('closeAlert', 'group-alert');
          }, 4000);
      })

      // modalArgs for segment tracking will be set in the open modal method
      // e.g in FeedCardActionMenu.vue openReportModal method call to dispatch openModal
      const { post, segmentData } = this.modalArgs;
      let { cardIndex } = this.modalArgs;
      if (post && segmentData) {
        let { context: { card }, userId } = segmentData;
        let cardType = card.type;
        const viewName = segmentData.context.view.name;
        const fullPostSegmentData = this.getCurrentFullPostSegmentData;
        if (fullPostSegmentData && fullPostSegmentData.context.card && !card.body) {
          card = fullPostSegmentData.context.card;
          cardIndex = fullPostSegmentData.cardIndex;
          cardType = 'full';
          userId = fullPostSegmentData.userId;
        }

        const properties = {
          context: {
            card: getSegmentGroupCard({ cardBody: card.body, cardIndex, cardType, post }),
            section: 'Action Menu',
            module: 'Action Menu',
            view: getSegmentGroupFeedView({ post, viewName }),
          },
          target: getSegmentGroupTarget({ post }),
          userId,
          sessionId: this.user.sessionId,
        };

        this.$segment.track('Report Post', properties);
        sendSegmentEvent('Report Post', properties, null);
      }
      if (this.modalArgs.callback) {
        this.modalArgs.callback();
      }
      this.closeModal();
    },

    closeModal() {
      this.$store.dispatch('closeModal');
    },
  },
};

