import _ from 'lodash';
import { CommunityStoryPropsI } from '@/userExperienceRedesign/modules/CommunityStory/types';
import AuthorController from '@/userExperienceRedesign/controllers/author';
import SponsorController from '@/userExperienceRedesign/controllers/sponsor';
import { AuthorI } from '@/userExperienceRedesign/types/author';
// using require to avoid TS untyped errors
const { cleanHTML, sanitizeText } = require('@/data/card-helpers');
const { getStoryUrl } = require('@/data/helpers');

export default class CommunityStoryController {
  protected data?: CommunityStoryPropsI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    const author = new AuthorController(post);

    this.data = {
      post,
      author: author.value as AuthorI,
      avatarGroup: [], // TODO
      avatarGroupBlurb: '', // TODO
      category: {
        label: undefined, // TODO: Removing for story previews but will need to restore for curated streams in the future - MGHTY-72: post.communityTopic.title
        color: undefined, // TODO: Same as above - MGHTY-72
      },
      contentPreview: CommunityStoryController.sanitizeBody(post),
      expandButtonLabel: 'Continue Reading',
      fullStoryURL: getStoryUrl({ post }),
      // fullViewButtonLabel?: string,
      headline: sanitizeText({ content: post.title, topics: post.topics, mentions: post.mentions, embeds: post.embeds }).replace(/<(.|\n)*?>/gi, ''),
      // We might have to restructure our component to expect html rather than a url
      imageSrc: CommunityStoryController.postImage(post),
      // reactionsCount?: number; //TODO
      sponsor: CommunityStoryController.postSponsor(post),
    };
  }

  protected static isRegularStory(post: any) {
    return post.sponsored === null;
  }

  protected static postSponsor(post: any) {
    if (CommunityStoryController.isRegularStory(post)) return post.sponsored;
    return new SponsorController(post).value;
  }

  protected static postImage(post: any) {
    return post.card_data['card-image'] ? post.card_data['card-image'].replace(/^.*src="(.*?)".*$/, '$1') : '';
  }

  protected static sanitizeBody(post: any) {
    let postDescription = post.card_data['card-description'];
    // NOTE: Gets body from post object, fallsback to empty string if none - MGHTY-157
    postDescription = sanitizeText({ content: cleanHTML(_.get(post, 'body', '')), topics: post.topics, mentions: post.mentions, embeds: post.embeds });

    // NOTE: To remove ellipsis & 'New' from post description - MGHTY-77/MGHTY-94
    postDescription = postDescription.replace('[...]', '');
    postDescription = postDescription.replace(
      '<span class="new-post">New</span>',
      '',
    );
    postDescription = postDescription.replace(/<(.|\n)*?>/gi, '');

    return postDescription;
  }
}
