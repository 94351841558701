
import Vue, { PropType } from "vue";
import vue from "vue";
import segmentAnalytics from "@/libs/events";
import { StreamItem } from "@/types/streamItem";
import CommunityVoicesIcon from "@/userExperienceRedesign/components/Icon/communityVoicesIcon.vue";
import VueResizeObserver from "vue-resize-observer";
import { PostI } from "./postHighlight";
import OutlinedButton from "../../components/OutlinedButton/index.vue";
import StoryAuthorInfo from "../../components/StoryAuthorInfo/index.vue";
import CommunityLabel from "../../components/CommunityLabels/CommunityLabel.vue";
import PeopleAreTalkingAboutThis from "../../components/PeopleAreTalkingAboutThis/index.vue";

function clickHandler(
  currentPath: string,
  contentPath: string,
  fullViewButtonLabel: string,
  post: StreamItem,
  index: number
): void {
  const user = post.author || post.author_id;
  segmentAnalytics.choose({
    path: contentPath,
    post: {
      id: post.id,
      title: post.title,
      topic: {
        ids: (post.topics || []).map((t: { id: string }) => t.id),
        names: (post.topics || []).map((t: { title: string }) => t.title),
      },
      user: {
        id: user.id,
        username: user.username,
      },
      type: post.type,
      pinned: post.isPinned,
    },
    context: {
      module: "NLM_Post",
      path: currentPath,
      card: {
        cta: {
          text: fullViewButtonLabel,
        },
        index: index as number,
      },
      view: "topic_feed",
    },
    target: {
      name: "module_cta",
      type: "button",
    },
  });
}

function clickHandlerEngagement(
  action: string,
  fullViewButtonLabel: string,
  post: StreamItem,
  index: number
): void {
  const contentPath = `${window.location.pathname}content/${post.id}`;
  const user = post.author;
  segmentAnalytics.choose({
    path: contentPath,
    post: {
      id: post.id,
      title: post.title,
      topic: {
        ids: (post.topics || []).map((t: { id: string }) => t.id),
        names: (post.topics || []).map((t: { title: string }) => t.title),
      },
      user: {
        id: user.id,
        username: user.username,
      },
      type: post.type,
      pinned: post.isPinned,
    },
    context: {
      module: "NLM_Post",
      path: window.location.pathname,
      card: {
        cta: {
          text: fullViewButtonLabel,
        },
        index: index as number,
      },
      view: "topic_feed",
    },
    target: {
      name: action,
      type: action === "Follow" ? "user" : "icon",
    },
  });
}

// TODO, moves this into a entry point js files
Vue.use(VueResizeObserver);

const checkBodyHeight = (
  contentPreviewTextHeightPx: number,
  lineHeight: string
) => {
  const maxLines = 7;
  // TODO: get rid of type casting
  const maxHeightPx = parseInt(lineHeight, 10) * maxLines;
  return contentPreviewTextHeightPx <= maxHeightPx;
};

export default vue.extend({
  name: "CommunityHighlights",
  components: {
    PeopleAreTalkingAboutThis,
    OutlinedButton,
    StoryAuthorInfo,
    CommunityLabel,
    CommunityVoicesIcon,
  },
  data: () => ({
    isPreviewExpanded: false,
    hideAuthorTitle: true,
  }),
  props: {
    /**
     * Data about the post's author
     */
    author: {
      type: Object as PropType<PostI["author"]>,
    },
    /**
     * An array of avatar URL strings for the people who reacted to the post
     */
    avatarGroup: {
      type: Array as PropType<PostI["avatarGroup"]>,
    },
    /**
     * Metadata for the post's category
     */
    category: {
      type: Object as PropType<PostI["category"]>,
    },
    /**
     * The number of people in the community
     */
    communitySize: {
      type: Number as PropType<PostI["communitySize"]>,
    },
    /**
     * At the end of the preview text, there is a button that links to the full story.
     * Whatever text is passed here will be rendered inside the button
     */
    fullViewButtonLabel: {
      type: String as PropType<PostI["fullViewButtonLabel"]>,
      default: "View the Conversation",
    },
    /**
     * The URL for the post's hero image
     */
    image: {
      type: String as PropType<PostI["image"]>,
    },
    /**
     * The title of the post
     */
    title: {
      type: String as PropType<PostI["title"]>,
    },
    /**
     * A permalink to the post
     */
    url: {
      type: String,
    },
    /**
     * A brief block of introduction text to give the user a preview of what the post is
     */
    contentPreview: {
      type: String,
    },
    /**
     * When the content preview text is too long to fit onto 5 lines, a button is shown to show the full block of text.
     * Whatever text is passed here will be rendered inside the button
     */
    expandButtonLabel: {
      type: String as PropType<PostI["expandButtonLabel"]>,
      default: "See More",
    },
    post: {
      required: true,
      type: Object,
    },
    index: {
      required: true,
    },
    segmentData: {
      required: true,
      type: Object,
    },
  },
  methods: {
    expandPreview() {
      this.isPreviewExpanded = true;
    },
    onResize({ height }: { height: string; width: string }) {
      if (!this.$refs.contentPreviewText) return;
      const { lineHeight } = getComputedStyle(
        this.$refs.contentPreviewText as HTMLParagraphElement
      );
      this.isPreviewExpanded = checkBodyHeight(
        parseInt(height, 10),
        lineHeight
      );
    },
    openModal() {
      const currentPath = window.location.pathname;
      this.$store.dispatch("updateCurrentFullPostSegmentData", {
        data: { ...(this.segmentData as object), cardIndex: this.index },
      });
      this.$store.dispatch("updateCurrentFullPost", {
        data: this.post,
        options: {
          profilePage: this.segmentData.context.view === "profile_feed",
        },
      });
      this.$store.dispatch("openModal", { id: "view-full-post" });
      const contentPath = window.location.pathname;
      const { fullViewButtonLabel, post, index } = this;

      clickHandler(
        currentPath,
        contentPath,
        fullViewButtonLabel as string,
        post as StreamItem,
        index as number
      );
    },
  },
  mounted() {
    if (this.contentPreview && this.$refs.contentPreviewText) {
      const { height } = getComputedStyle(
        this.$refs.contentPreviewText as HTMLParagraphElement
      );
      this.isPreviewExpanded = checkBodyHeight(parseInt(height, 10), height);
    }

    const { fullViewButtonLabel, post, index } = this;
    const sectionRef = this.$refs.sectionRef as HTMLElement;
    sectionRef
      .querySelector(".author-info__followBtn")
      ?.addEventListener("click", () =>
        clickHandlerEngagement(
          "Follow",
          fullViewButtonLabel as string,
          post as StreamItem,
          index as number
        )
      );
  },
  computed: {
    peopleTalkingBlurb() {
      const population = this.communitySize === 1 ? "person is" : "people are";
      return `${this.communitySize} ${population} talking about this`;
    },
  },
});
