
import vue, { PropType } from 'vue';
import NewsletterAdvocates from '@/userExperienceRedesign/modules/Newsletter/NewsletterAdvocates/index.vue';
import NewsletterCollaborations from '@/userExperienceRedesign/modules/Newsletter/NewsletterCollaborations/index.vue';
import NewsletterPullquote from '@/userExperienceRedesign/modules/Newsletter/NewsletterPullquote/index.vue';
import NewsletterTheMighty from '@/userExperienceRedesign/modules/Newsletter/NewsletterTheMighty/index.vue';
import NewsletterWithImage from '@/userExperienceRedesign/modules/Newsletter/NewsletterWithImage/index.vue';
import { newsLetterModuleProps, NewsletterProxyPropsI, NewsletterPropsI } from './types';

export default vue.extend({
  name: 'Newsletter',
  components: { NewsletterAdvocates, NewsletterCollaborations, NewsletterPullquote, NewsletterTheMighty, NewsletterWithImage },
  props: {
    newsletter: newsLetterModuleProps as unknown as PropType<NewsletterPropsI>,
    index: {
      required: true,
    },
    newsletterType: {
      type: String as PropType<NewsletterProxyPropsI['newsletterType']>,
      validator: (value: string): boolean => ([
        'NEWSLETTER_ADVOCATES',
        'NEWSLETTER_COLLABORATIONS',
        'NEWSLETTER_PULLQUOTE',
        'NEWSLETTER_BG_IMAGE',
        'NEWSLETTER_BASIC',
      ].includes(value)),
    },
  },
  computed: {
    isAdvocates() {
      return this.newsletterType === 'NEWSLETTER_ADVOCATES';
    },
    isCollaborations() {
      return this.newsletterType === 'NEWSLETTER_COLLABORATIONS';
    },
    isPullquote() {
      return this.newsletterType === 'NEWSLETTER_PULLQUOTE';
    },
    isBgImage() {
      return this.newsletterType === 'NEWSLETTER_BG_IMAGE';
    },
    isBasic() {
      return this.newsletterType === 'NEWSLETTER_BASIC';
    },
  },
});
