
import _ from "lodash";
import { mapGetters } from "vuex";
import { staticPath } from "@/data/helpers";
import { timeLeft } from '@/shared/utils/general';

export default {
  name: "PollStatus",
  props: ["isClosed", "post"],
  data() {
    return {
    };
  },
  computed: {
    pollStatus() {
      return !this.isClosed ? timeLeft(this.post) : "Final Results";
    },
    lastHour() {
      return this.pollStatus === '1 hour left!';
    },
  },
  methods: {
    timeLeft,
    staticPath,
  },
};
