
import _ from 'lodash';

export default {
  name: 'ExternalLinkPreview',
  data() {
    return {};
  },
  props: {
    link: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    openGraph() {
      return _.get(this.link, 'openGraph', {});
    },
    url() {
      return _.get(this.link, 'url', '#');
    },
    imageURL() {
      return _.get(this.openGraph, 'image.url', '');
    },
    title() {
      return _.get(this.openGraph, 'title', '');
    },
    description() {
      // if a mighty video, don't show description
      if (_.get(this.openGraph, 'url').includes('themighty.com/video/')) {
        return '';
      }

      return _.get(this.openGraph, 'description', '');
    },
    isHTML() {
      // saw at least one scenario where the description was HTML
      // however, since its a snippet (first 120 char I think), there are missing closing brackets, so decided not to show it
      return /<\/?[a-z][\s\S]*>/i.test(this.description);
    },
  },
};
