
import InviewMixin from '@/plugins/InviewMixin';
import _ from 'lodash';
import { staticPath } from '@/data/helpers';
import { getButtonMessages } from '@/data/api/amplify_data';
import segmentAnalytics from '@/libs/events';
import FindSupport from './FindSupport';
import ExploreTopics from './ExploreTopics';
import WhyJoinTheMighty from './WhyJoinTheMighty';
import LandingPage from './LandingPage';
import LookingToCollaborate from './LookingToCollaborate';

export default {
  name: 'WelcomeHomePage',
  components: { FindSupport, ExploreTopics, WhyJoinTheMighty, LookingToCollaborate, LandingPage },
  mixins: [InviewMixin],
  data() {
    return {
      previousScrollY: 0,
      stickyButtonOpacity: 0,
      stickyButtonMessages: [],
      stickyButtonIdx: 0,
      stickyButtonStart: 0,
    }
  },
  async mounted() {
    segmentAnalytics.view({
      context: {
        view: 'nlm_homepage',
      },
    });

    // waiting a little for Why Join section images to load, then setting up sticky button functionality
    setTimeout(() => {
      const whyJoin = document.getElementById('why-join-the-mighty');
      if (whyJoin) {
        this.stickyButtonStart = whyJoin.offsetTop;
      }
      const welcomePage = document.getElementById('welcome-page');
      if (welcomePage && whyJoin) {
        window.addEventListener('scroll', this.windowScroll, false);
      }
      // in the event page loads from the middle/bottom
      if (window.scrollY > this.stickyButtonStart) {
        this.stickyButtonOpacity = 1;
      }
    }, 500);


    getButtonMessages()
      .then((messages) => {
        this.stickyButtonMessages = messages;
        setInterval(this.cycleIndexes, 5000);
      })

  },
  methods: {
    staticPath,
    openRegistration(action) {
      this.$store.dispatch('openModal', {
        id: 'login-registration-onboarding',
        args: { action },
      }).then(() => {
        segmentAnalytics.choose({
          target: {
            name: 'Join Us',
            type: 'button',
            message: this.message?.text | '',
          },
          context: {
            section: 'sticky_button',
          }
        });
      });
    },
    cycleIndexes() {
      if (this.stickyButtonIdx === this.stickyButtonMessages.length - 1) {
        this.stickyButtonIdx = 0;
      } else {
        this.stickyButtonIdx += 1;
      }
    },
    windowScroll(e) {
      const begin = this.stickyButtonStart - 125;
      if (window.scrollY >= begin && this.previousScrollY < window.scrollY && this.stickyButtonOpacity < 1) {
        this.stickyButtonOpacity += 0.10;
      } else if (window.scrollY < begin) {
        this.stickyButtonOpacity = 0;
      } else if (window.scrollY < (begin + 200) && this.previousScrollY > window.scrollY && this.stickyButtonOpacity > 0) {
        this.stickyButtonOpacity -= 0.10;
      }

      this.previousScrollY = window.scrollY;
    },

  },
  computed: {
    message() {
      return this.stickyButtonMessages[this.stickyButtonIdx];
    }
  }
};
