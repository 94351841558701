
import { getFeaturedTopics } from '@/data/api/amplify_data';
import segmentAnalytics from '@/libs/events';

export default {
    name: 'ExploreTopics',
    data() {
        return {
          selected: 0,
          topics: [],
        }
    },
    mounted() {
      getFeaturedTopics()
        .then((topics) => {
          this.topics = topics || [];
        })
    },
    computed: {
      sortedTopics() {
        return [{ name: 'Explore All' }, ...this.topics.sort((a, b) => a.order - b.order)]
      },
    },
    methods: {
    selectTopic(idx, slug, event, navigate) {
      this.selected = idx;
      segmentAnalytics.choose({
        target: {
          name: slug,
          type: 'button',
        },
      });
      navigate(event);
    }
  }
}
