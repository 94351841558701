
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { isSet, isObject, sendSegmentEvent } from '@/data/helpers';
import BaseFeed from '@/feed/BaseFeed';
import FeaturedFeed from '@/feed/FeaturedFeed/index.vue';
import ReportPost from "@/feed/ReportPost";
import AddSponsorship from "@/feed/AddSponsorship";
import WelcomeHomePage from '@/feed/WelcomeHomePage';
import HomeQuickSearch from './HomeQuickSearch';

export default {
  name: 'HomeFeedContainer',

  // register components
  components: {
    BaseFeed,
    FeaturedFeed,
    WelcomeHomePage,
    HomeQuickSearch,
    ReportPost,
    AddSponsorship,
    AffirmationWindow: () => import('./Affirmation')
  },

  data() {
    return {
      selectedTab: 0,
      loading: true,
      navbarHeight: 0,
      featuredFeedBottom: null,
    };
  },
  mounted() {
    this.$segment.page('Home');
    const name = 'Home';
    const properties = {
      name,
      path: this.$route.path,
      title: process.browser ? document.title : '',
      url: process.browser ? window.location.href : '',
      userId: this.user._id,
      sessionId: this.user.sessionId,
    }
      sendSegmentEvent('Page Load', properties, null, { type: "page", name });

    const hash = this.$route.hash.substr(1);
    if (hash === 'privacy-preference-center') {
        setTimeout(this.openPreferenceCenter, 2500);
    }
  },
  fetch() {
    if (process.client && window.dfp) {
      window.dfp.resetAds();
      window.dfp.init();
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll);
  },
  watch: {
    loggedInUser: {
      immediate: true,
      async handler(newVal, oldVal) {

        if (this.loggedInUser && !this.getMygroups.length) {
          this.$store.dispatch('loadMyGroups', { username: this.user.username });
        }

        if (this.loggedInUser) {
          if (this.isInitialLoadCompleted) {
            return;
          }

          const data = {};
          const affirmationId = _.get(this.user, 'settings.selected_affirmation_id');
          const expireAt = moment(_.get(this.user, 'settings.selected_affirmation_expire_at'));

          if (affirmationId && !this.hideAffirmations && expireAt.isValid() && expireAt.diff(new Date(), 'seconds') > 0) {
            data.affirmationId = affirmationId;
          }

          await this.$store.dispatch('loadInitialFeed', data)
            .then((res) => {
              this.$store.commit('initalLoadCompleted');
            })
        }
      },
    },
    isInitialLoadCompleted: {
      immediate: true,
      handler() {
        // after done loading, grab height of navbar, and add event listener
        // for scrolling, which ensures the Featured Feed stops at the footer
        if (this.isInitialLoadCompleted) {
          const nav = document.getElementById('site-new-navigation');
          if (nav) {
            this.navbarHeight = nav.offsetHeight || 85;
          }
          window.addEventListener("scroll", this.checkScroll);
        }
      },
    },
    feedLength: {
      immediate: true,
      handler(newVal, oldVal) {
        // this is for when a user loads more feed items (ie. clicks See More),
        // we re-anchor the Featured Feed to the top of the page
        this.featuredFeedBottom = null;
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getMygroups: 'getMygroups',
      getFeedItems: "getFeedItems",
      getRecommendedFollows: 'getRecommendedFollows',
      getFeaturedCollections: 'getFeaturedCollections',
      getFeaturedTopics: 'getFeaturedTopics',
      getAffirmationCategories: 'getAffirmationCategories',
      isInitialLoadCompleted: 'isInitialLoadCompleted',
    }),

    isModalReportPostOpen() {
      return this.$store.getters.isModalOpen("report-post");
    },
    modalReportPostArgs() {
      if (this.isModalReportPostOpen) {
        return this.$store.getters.getOpenModalExtraArgs;
      }
      return {};
    },

    isModalAddSponsorshipOpen() {
      return this.$store.getters.isModalOpen("add-sponsorship");
    },

    modalAddSponsorshipArgs() {
      if (this.isModalAddSponsorshipOpen) {
        return this.$store.getters.getOpenModalExtraArgs;
      }
      return {};
    },

    loggedInUser() {
      return !_.isNil(this.user._id);
    },
    tabs() {
      return [
        { label: 'Your Feed', feedname: 'personalized' },
        { label: 'New Members', feedname: 'new_members', types: ['NEW_MEMBER'] },
        { label: 'Stories', feedname: 'stories', types: ['WORDPRESS_POST', 'WORDPRESS_VIDEO'] },
        { label: 'Polls', feedname: 'polls', types: ['POLL'] },
        { label: 'Posts', feedname: 'posts', types: ['QUESTION', 'THOUGHT'] },
      ]
    },
    feedLength() {
      return (this.getFeedItems(this.tabs[this.selectedTab].feedname) || []).length;
    },
    feedCardOptions: () => ({
      humanNumber: true,
      humanNumberFirstValue: false,
      utmCampaignPrefix: 'home_feed.recommended',
    }),
    hideAffirmations() {
      return _.get(this.user, 'settings.hide_affirmations', false);
    },
    featuredFeedSticky() {
      return this.featuredFeedBottom !== null;
    },
  },
  methods: {
    openPreferenceCenter() {
      this.$store.commit('setCookiesPreferenceCenter', true);
    },
    checkScroll() {
      if (this.$refs['home-feed']) {
        /*
        // using this formula to dictate is user scrolled down to where home feed ends and footer begins
        // the finding of this formula was pretty much guess and check
        // left side of equation is the scroll position, while the right side is the home feed height
        // the result is how much of the footer is visible, which then is where we need to place the featured feed
        */
        const footerVisible = (window.scrollY + window.innerHeight - this.navbarHeight) - this.$refs['home-feed'].offsetHeight;
        if (footerVisible > 0) {
          this.featuredFeedBottom = footerVisible;
          return;
        }
      }
      this.featuredFeedBottom = null;
    },
  },
};
