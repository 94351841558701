// @ts-nocheck
import { log } from '@/data/helpers';

const mixin = {
  methods: {
    handleInViewEvent() {
      this.handleElementInview();
    },

    // must be implemented in the component
    handleElementInview() {
      log('handleElementInview', this.$el);
    },
  },
  mounted() {
    const uniqueClass = `inview-${this._uid}`;
    if (!('classList' in this.$el)) {
      log(this._name, 'Element is not attached to the DOM');
      return;
    }
    this.$el.classList.add(uniqueClass);
    const inViewSelector = `.${uniqueClass}`;
    if (this.repeatInview) {
      this.$inView(inViewSelector).on('enter', this.handleInViewEvent);
    } else {
      this.$inView(inViewSelector).once('enter', this.handleInViewEvent);
    }
  },
};

export default mixin;
