
import vue from 'vue';
import PostHeartsButton from './PostHeartsButton/PostHeartsButton.vue';
import PostShareButton from './PostShareButton/PostShareButton.vue';
import PostCommentButton from './PostCommentButton/PostCommentButton.vue';
import PostSaveButton from './PostSaveButton/PostSaveButton.vue';
import './social-button-group.scss';

export default vue.extend({
  name: 'LikeShareButton',
  components: {
    PostHeartsButton,
    PostShareButton,
    PostCommentButton,
    PostSaveButton,
  },
  props: {
    /** Boolean to determine whether component is on a dark background, if true, icons and text
     * will change to white */
    isOnDarkBackground: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickShare() {
      this.$emit('onClickShare');
    },
    onClickLike() {
      this.$emit('onClickLike');
    },
  },
});
