import { isObject } from '@/data/helpers';

export default {
  computed: {
    author() {
      // default to empty object
      if (!isObject(this.post)) {
        return {};
      }

      let key;
      // get from author key
      if (isObject(this.post.author)) {
        key = 'author';
      } else if (isObject(this.post.author_id)) {
        key = 'author_id';
      }

      if (key) {
        const author = this.post[key];
        return {
          url: `/u/${author.username}/`,
          _id: author._id,
          id: author._id, // we only ever use the mongoId in vue. never the wordpress id
          avatar: `<div class="avatar_circle avatar " style="background-image: url('${author.profile.avatar_thumbnail}'); display:block;"></div>`,
          name: author.display_name,
          role: author.profile.role,
          byline: `@${author.username}`,
          byline_url: `/u/${author.username}/`,
        };
      }

      // otherwise build it from the card data
      const authorUrl = this.card_data['card-author-url'];
      const isUrl = authorUrl.includes('http');

      return {
        url: isUrl ? new URL(authorUrl).pathname : authorUrl,
        avatar: this.card_data['card-byline-avatar'],
        name: this.card_data['card-byline-author'],
        byline: this.card_data['card-author-byline'],
        byline_url: this.card_data['card-author-byline-url'],
        role: (this.card_data['card-author-roles'] || [''])[0],
      };
    },
  },
};
