

export default {
  name: 'ExternalCloseX',
  props: {
    args: {
      default() {
        return {
          disabled: false,
          classes: '',
        };
      },
    },
  },
  computed: {
    extraClasses() {
      const classes = {};
      classes[this.args.classes] = !!this.args.classes;
      return classes;
    },
  },
  methods: {
    passClick(e) {
      if (!this.args.disabled) {
        this.$emit('click', e);
      }
    },
  },
};
