
import { staticPath } from '@/data/helpers';

export default {
  name: 'ExpertBadge',
  props: ['user'],
  computed: {
    cssProps() {
      return {
        '--check': `url('${staticPath('check.png')}')`,
        '--check2x': `url('${staticPath('check@2x.png')}')`,
        '--check3x': `url('${staticPath('check@3x.png')}')`,
      };
    },
    badges() {
      if (!this.user) {
        return false;
      }
      const { badge } = (this.user.profile || {}).flags || { badge: [] };
      return badge;
    },
  },
  methods: {
    staticPath,
  },
};
