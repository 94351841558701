import { StreamItem } from '@/types/streamItem';
import _ from 'lodash';

export const isStory = (post: Object): Boolean => _isStoryType(post) && !_isSponsored(post) && !_hasVideo(post);

export const isCommunityHighlight = (post: Object): Boolean => ['THOUGHT', 'QUESTION'].includes((post as StreamItem).type);

export const isEvent = (post: Object): Boolean => (post as StreamItem).type === 'EVENT';

export const isNewsletter = (post: Object): Boolean => (post as StreamItem).type === 'NEWSLETTER';

export const isSponsoredStory = (post: Object): Boolean => _isStoryType(post) && _isSponsored(post) && !_hasVideo(post);

export const isSpotlight = (post: Object): Boolean => (post as StreamItem).type === 'SPOTLIGHT';

export const isCommunityVideo = (post: Object): Boolean => {
  const isWpVideoType = (post as StreamItem).type === 'WORDPRESS_VIDEO';
  return isWpVideoType || _hasVideo(post) as boolean;
};

const _isSponsored = (post: Object) => _.get((post as StreamItem), 'sponsored.is', false);

const _isStoryType = (post: Object) => ['WORDPRESS_POST', 'story'].includes((post as StreamItem).type);

const _hasVideo = (post: Object) => !_.isUndefined((post as StreamItem).video);
