
// libs
import _ from 'lodash';
import { mapGetters } from 'vuex';
import fuzzysearch from 'fuzzysearch';
import Dropdown from '@/components/Dropdown';
import BlockModal from '../../components/BlockModal';

export default {
  name: 'AddSponsorship',
  components: { BlockModal, Dropdown },
  // local props
  props: {
    id: 0,
    modalOpen: {
      default: false,
    },
    modalName: {
      default: 'add-sponsorship',
    },
    modalArgs: {},
  },
  data() {
    return {
      partnerOptions: [],
      partnerIndex: 0,
      filterText: '',
    };
  },

  // load props from vuex and any other derived props
  computed: {
    ...mapGetters({
      user: 'user',
    }),

    open() {
      return this.modalOpen;
    },
    filteredOptions() {
      return this.partnerOptions
        .filter(option => fuzzysearch(this.filterText.toLowerCase(), _.get(option, 'label').toLowerCase()))
        .sort((a, b) => _.get(a, 'label', '').toLowerCase().localeCompare(_.get(b, 'label', '').toLowerCase()));
    },
  },

  watch: {
    modalOpen() {
      if (this.modalOpen && this.user.role_slug === 'administrator') {
        this.$nodeAxios.get('/api/v1/partners/list')
          .then((response) => {
            this.partnerOptions = response.data.map(x =>
              ({
                label: x.display_name,
                value: x.platforms.wordpress,
              }),
            );
          });
      }
    },
  },

  // actions formed inside this element
  methods: {
    closeModal() {
      this.$store.dispatch('closeModal');
    },
    addSponsorship() {
      this.$nodeAxios.post(`/api/v1/content/add_sponsor/${this.modalArgs.content_id}?sponsor_id=${this.filteredOptions[this.partnerIndex].value}`)
        .then((response) => {
          if (response.data) {
            location.reload();
            this.$store.dispatch('closeModal');
          }
        });
    },
  },
};

