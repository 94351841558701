
/* eslint no-console:0 */
// libs
import ExternalCloseX from '@/components/ExternalCloseX';
import { staticPath } from '@/data/helpers';

export default {
  name: 'Modal',
  components: { ExternalCloseX },
  props: {
    open: {
      default: false,
    },
    internalCloseX: {
      default: true,
    },
    externalCloseX: {
      default: false,
    },
    canClickBackdrop: {
      default: true,
    },
  },
  methods: {
    backdropClick() {
      if (this.canClickBackdrop) {
        this.closeThisModal();
      }
    },
    staticPath,
    closeThisModal() {
      this.$store.dispatch('closeModals');
      this.$emit('close');
    },
  },
  watch: {
    $route(val) {
      // need not to close modal on this updated Route
      if (val && val.hash === '#settings,settings-submit-story-ui') {
        return;
      }
      this.closeThisModal();
    },
  }
};

