import { loadScript } from './helpers';

export async function loadVideo({
  playerId = 'uYsgFEWd', // JW8 Video Story Card Player
  videoId,
  id,
  autostart = false
}) {
  try {
    await loadScript({ src: `https://cdn.jwplayer.com/libraries/${playerId}.js` });
    const player = window.jwplayer(id);
    return player.setup({
      playlist: `https://cdn.jwplayer.com/v2/media/${videoId}?format=json`,
      autostart,
    });
  } catch (err) {
    console.log('Load video error', { playerId, videoId, id }, err);
  }
}


export default null;
