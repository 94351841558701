
import _ from 'lodash';
import Vue from 'vue';
import VueEvents from 'vue-events';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { log, isSet, isFunction, genOpenLinkCallback, siteUrl, getStoryUrl } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';
import InviewMixin from '@/plugins/InviewMixin';
import { triggerViewPost } from '@/libs/events';
import SmartBadge from '@/components/SmartBadge';

Vue.use(VueEvents);

// list of elements that accepts focus on the card (actionable elements)
const cardElementsAllowedFocus = 'a, .card-share-menu, .feed-card.sharing, .jwplayer';

export default {
  name: 'ContentCard',
  mixins: [InviewMixin],
  components: { SmartBadge },

  // passed in properties
  props: {
    post: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        humanNumber: true,
        humanNumberFirstValue: true,
        analyticsEventData: {},
      }),
    },
    cardContent: {
      default: '',
    },
    overrideOpenLink: {
      default: null,
    },
    gaCategory: {
      default: '',
    },
    cameFrom: {
      default: '',
    },
    segmentData: undefined,
  },

  // internal data for this element
  data() {
    return {
      isSharing: false,
      isFocusing: false,
      isPartialFocus: false,
    };
  },

  // create some virtual properties from other data bits
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    article() {
      if (isSet(this.post.card_data)) {
        return {
          url: getStoryUrl({ post: this.post }),
        };
      }

      return {
        url: '#missing',
      };
    },

    allowedElements() {
      if (isFunction(this.overrideAllowedElements)) {
        return this.overrideAllowedElements();
      }
      return cardElementsAllowedFocus;
    },
    linkSegmentData() {
      return _.pick(this.segmentData, 'context');
    },

    contentUrl() {
      return getStoryUrl({ post: this.post });
    },
  },


  // handle signals from children
  events: {
    // listens for share menu activity (open/close)
    // when the PostShareButton is activated we keep the state here
    toggleShareMenu(isSharing, post) {
      if (post === this.post) {
        this.isSharing = isSharing;
      }
    },
  },

  mounted() {
    this.segmentedLink();
  },

  beforeDestroy() {
    this.unmountSegmentedLink();
  },

  methods: {
    /**
     * Triggers PostShareButton close event when
     *   any place in the card is clicked (click outside share menu)
    */
    closeShareMenu(e) {
      // only triggers when clicked in the card itself (not share menu)
      const isClickOutsideMenu = e.target === this.$el;

      if (this.isSharing && isClickOutsideMenu) {
        // send event for PostShareButton
        this.$events.emit('closeShareMenu');
      }
    },

    /**
     * Determines if the given card element accepts focus
     * Some elements (defined in `allowedElements`)
     *   can also have the focus in front of the card itself
     */
    isCardElementFocusAllowed(element) {
      return ($(element).is(this.allowedElements) ||
              $(element).closest(this.allowedElements).length);
    },

    /**
     * Opens the card link when any place in card is clicked (not sharing)
     * The redirection only happens for clicks in non actionable elements
     */
    openCardLink(e) {
      log('CONTENTCARD[openCardLink]:', e, e.target, isFunction(this.overrideOpenLink));
      this.$store.commit('triggerCardOpenLink', {
        post: this.post,
        gaCategory: this.gaCategory,
        options: this.options,
        callback: isFunction(this.overrideOpenLink) ? this.overrideOpenLink(e) : this.openCardLinkFunction(e),
      });
      segmentAnalytics.choose(this.segmentData);
    },

    openCardLinkFunction(e) {
      if (!e.skipLinkCallback && !this.isCardElementFocusAllowed(e.target)) {
        return genOpenLinkCallback(this.article.url, e);
      }
      return null;
    },

    /**
     * Handles the card click and dispatch to multiple methods
     */
    handleCardClick(e) {
      this.closeShareMenu.call(this, e);
      if (!event.target.closest('.tm-embed-link, .tm-topic-link')) {
        this.openCardLink.call(this, e);
      }
    },

    /**
     * Handles card focus state (mouseenter/mouseleave)
     */
    toggleCardFocus() {
      this.isFocusing = !this.isFocusing;
    },

    /**
     * Handles card partial-focus state (mouseover/mouseout).
     * The card partial focus is a state where, while focused, the user can be pointing
     *   an actionable element from the card (eg. a link or button) and thus
     *   the main focus should be granted to this element and not the whole card.
     */
    addCardPartialFocus(e) {
      if (this.isCardElementFocusAllowed(e.target)) {
        this.isPartialFocus = true;
      }
    },
    removeCardPartialFocus(e) {
      if (this.isCardElementFocusAllowed(e.target)) {
        this.isPartialFocus = false;
      }
    },

    /**
     * Handles card inview event
     * Provided by InviewMixin
     */
    handleElementInview() {
      // not triggering for FeaturedFeed
      if (this.post.editorPick) {
        return;
      }
      this.$store.commit('triggerCardInview', {
        gaCategory: this.gaCategory,
        post: this.post,
        options: this.options,
      });
      const { post, segmentData: { context } } = this;
      triggerViewPost(post, context, this.user._id, this.user.sessionId || '');
    },

    segmentedLink() {
      $(this.$el).on('click', '.tm-topic-link', (e) => {
        segmentAnalytics.choose(_.merge(
          this.linkSegmentData,
          {
            target: {
              id: e.target.dataset.id,
              type: 'url.mighty.hashtag',
              name: e.target.dataset.name,
            },
          },
        ));
        e.stopPropagation();
      });
      $(this.$el).on('click', '.health-map', (e) => {
        segmentAnalytics.choose(_.merge(
          this.linkSegmentData,
          {
            target: {
              id: e.target.dataset.id,
              type: 'url.mighty.autolink',
              name: e.target.dataset.name,
            },
          },
        ));
        e.stopPropagation();
      });
      $(this.$el).on('click', '.mighty-link', (e) => {
        segmentAnalytics.choose(_.merge(
          this.linkSegmentData,
          {
            target: {
              id: e.target.dataset.id,
              type: 'url.mighty',
              name: e.target.dataset.name,
            },
          },
        ));
        e.stopPropagation();
      });
      $(this.$el).on('click', '.tm-embed-link:not(.mighty-link):not(.health-map)', (e) => {
        segmentAnalytics.choose(_.merge(
          this.linkSegmentData,
          {
            target: {
              id: e.target.dataset.name,
              type: 'url.external',
              name: e.target.dataset.name,
            },
          },
        ));
        e.stopPropagation();
      });
    },

    unmountSegmentedLink() {
      $(this.$el).off();
    },

    handleCardNavigation(event, navigate) {
      // if they click a partner link, don't handle card navigation
      if (event.target.className === 'partner-name' && event.target.href !== "") {
        return;
      }
      
      if (this.contentUrl) {
        // eslint-disable-next-line no-param-reassign
        event.skipLinkCallback = true;
        navigate(event);
      }
      this.handleCardClick(event);
    },
  },
};
