
import segmentAnalytics from '@/libs/events';

export default {
    name: 'CollectionItem',
    props: ['collection', 'filteredBy'],
    methods: {
      selectCollection(event, navigate) {
        segmentAnalytics.choose({
          userId: this.loggedIn,
          context: {
            section: this.filteredBy === 'Default' ? 'Recent' : this.filteredBy,
          },
          target: {
            name: 'Explore',
            type: 'button',
          },
          post: {
            id: this.collection.id,
            title: this.collection.title,
            type: 'collection',
          }
        });
        navigate(event);
      }
  }
}
