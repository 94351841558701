
import he from 'he';
import { get } from 'lodash';
import { siteUrl, getStoryUrl } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';

export default {
  name: 'StoryCardContent',
  props: ['post', 'options', 'segmentData'],
  computed: {
    card_data() {
      return this.post.card_data || {};
    },
    article() {
      return {
        url: this.getUrl(),
        title: he.decode(this.card_data['card-title'] || ''),
        image: this.card_data['card-image'],
        squareImage: this.card_data['card-image-square'],
        description: this.card_data['card-description'],
      };
    },
  },
  methods: {
    getUrl() {
      return getStoryUrl({ post: this.post });
    },
    triggerOpenLink(e, navigate) {
      segmentAnalytics.choose(this.segmentData);
      this.$store.commit('triggerCardOpenLink', {
        post: this.post,
        options: this.options,
        callback: () => {},
      });
      navigate(e);
    },
  },
};
