
import { mapGetters } from 'vuex';

export default {
    name: 'FeaturedCollections',
    data() {
        return {
            hovered: null,
        }
    },
    methods: {
        setHovered(idx, setting) {
            if (setting === 'in') {
                this.hovered = idx;
            } else if (this.hovered === idx) {
                this.hovered = null;
            }
        },
    },
    computed: {
        ...mapGetters({
            collections: 'getFeaturedCollections',
        }),
        featuredCollections() {
            return this.collections.slice(0, 3);
        },
    }
}
