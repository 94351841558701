
import vue, { PropType } from 'vue';
import segmentAnalytics from '@/libs/events';
import { SpotlightPropsI } from './types';
import Avatar from '../../components/Avatar/index.vue';
import AvatarGroup from '../../components/AvatarGroup/index.vue';
import SponsorHeading from '../../components/SponsorHeading/index.vue';
import OutlinedButton from '../../components/OutlinedButton/index.vue';

export default vue.extend({
  name: 'Spotlight',
  components: { Avatar, AvatarGroup, OutlinedButton, SponsorHeading },
  props: {
    /**
     * An array of data (images) for the AvatarGroup component
     */
    avatarGroup: {
      type: Array as PropType<SpotlightPropsI['avatarGroup']>,
    },
    /**
     * Accepts a string for the social engagement component related to spotlight
     */
    avatarGroupBlurb: {
      type: String as PropType<SpotlightPropsI['avatarGroupBlurb']>,
    },
    /**
     * Accepts a string for the social engagement button related to spotlight
     */
    ctaLabel: {
      type: String as PropType<SpotlightPropsI['ctaLabel']>,
      required: true,
    },
    /**
     * Accepts a string for the social engagement button related to spotlight
     */
    ctaUrl: {
      type: String as PropType<SpotlightPropsI['ctaUrl']>,
      required: true,
    },
    /**
     * Accepts a string for description of spotlight
     */
    description: {
      type: String as PropType<SpotlightPropsI['description']>,
      required: true,
    },
    /**
     * Accepts a string for the image related to spotlight
     */
    imageSrc: {
      type: String as PropType<SpotlightPropsI['imageSrc']>,
      required: true,
    },
    /**
     * Accepts a string for label related to the spotlight author
     */
    label: {
      type: String as PropType<SpotlightPropsI['label']>,
      required: true,
    },
    /**
     * A string for name of spotlight author
     */
    name: {
      type: String as PropType<SpotlightPropsI['name']>,
      required: true,
    },
    /**
     * A object containing related sponsor information
     */
    sponsor: {
      type: Object as PropType<SpotlightPropsI['sponsor']>,
    },
    index: {
      type: Number,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    descriptionDesktopStyles() {
      if (!this.imageSrc) {
        return 'descriptionDesktopStyle';
      }
      return '';
    },
  },
  methods: {
    onClick() {
      const { ctaLabel, ctaUrl, post, index } = this;
      const user = post.author;
      segmentAnalytics.choose({
        path: ctaUrl,
        post: {
          id: post.id,
          title: post.title,
          topic: {
            ids: (post.topics || []).map((t: {id: string}) => t.id),
            names: (post.topics || []).map((t: {title: string}) => t.title),
          },
          user: {
            id: user.id,
            username: user.username,
          },
          type: post.type,
          pinned: post.isPinned,
        },
        context: {
          module: 'NLM_Spotlight',
          path: window.location.pathname,
          card: {
            cta: {
              text: ctaLabel,
            },
            index,
          },
          view: 'topic_feed',
        },
        target: {
          name: 'module_cta',
          type: 'button',
        },
      });
    },
  },
});
