
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { FOLLOW_TYPE_USER } from '@/data/enum/follow';

export default {
  name: 'IntantFollowCta',
  props: ['gaCategory', 'authorId', 'post', 'segmentData'],
  computed: {
    ...mapGetters({
      currentUser: 'user',
    }),
    loggedIn() {
      return !_.isNil(this.currentUser._id);
    },
    followed() {
      return this.$store.getters.userFollowedUser(this.authorId);
    },
    showFollow() {
      return !this.followed && this.authorId !== this.currentUser._id;
    },
  },
  methods: {
    toggleUserFollowing(e) {
      e.stopPropagation();
      if (!this.loggedIn) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }

      this.$store.dispatch('toggleFollow', {
        itemType: FOLLOW_TYPE_USER,
        itemId: this.authorId,
        initiator: 'instantFollowCTA',
        followName: this.post?.author_id?.username,
        following: this.followed,
      });

      this.$store.dispatch('voiceEvent', {
        category: this.gaCategory,
        action: 'follow',
        label: this.authorId,
      });
    },
  },
};
