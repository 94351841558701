
import vue from 'vue';
import { newsLetterModuleProps } from '../types';
import OutlinedButton from '@/userExperienceRedesign/components/OutlinedButton/index.vue';
import SocialButtonGroup from '@/userExperienceRedesign/components/LikeShareButtons/SocialButtonGroup.vue';


export default vue.extend({
  name: 'NewsletterWithImage',
  components: { OutlinedButton, SocialButtonGroup },
  props: newsLetterModuleProps,
});
