
import vue from 'vue';
import { newsLetterModuleProps } from '../types';
import OutlinedButton from '@/userExperienceRedesign/components/OutlinedButton/index.vue';

export default vue.extend({
  name: 'NewsletterPartnerships',
  components: { OutlinedButton },
  props: newsLetterModuleProps,
});
