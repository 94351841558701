
import vue, { PropType } from 'vue';
import { AvatarGroupPropsI } from './types';
import Avatar from '../Avatar/index.vue';

export default vue.extend({
  name: 'AvatarGroup',
  components: { Avatar },
  props: {
    avatarGroup: {
      type: Array as PropType<AvatarGroupPropsI['avatarGroup']>,
      required: true,
    },
    size: {
      type: Number as PropType<AvatarGroupPropsI['size']>,
      default: 24,
    },
  },
  computed: {
    marginOffset(): number {
      return this.size / 2;
    },
  },
});
