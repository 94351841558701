
import { get } from 'lodash';

/* Made this component separate mainly in an attempt to declutter PollContent */
export default {
    name: 'PollActionCounts',
    props: ['post', 'selectedAnswers', 'voteCount'],
    computed: {
        reactions() {
            return get(this.post, 'action_counts.hearts', 0) + get(this.post, 'action_counts.reactions_count', 0);
        },
        comments() {
            return get(this.post, 'action_counts.comments', 0);
        },
        hasReactions() {
            return this.reactions > 0;
        },
        hasComments() {
            return this.comments > 0;
        },
        actionCounts() {
            let counts = [];
            if (this.voteCount) {
                counts.push(this.voteCount);
            }
            if (this.reactions > 0) {
                counts.push(`${this.reactions} reaction${this.reactions === 1 ? '' : 's'}`);
            }
            if (this.comments > 0) {
                counts.push(`${this.comments} comment${this.comments === 1 ? '' : 's'}`);
            }
            return counts;
        },
    },
    methods: {
        openFullPost() {
            // storing any existing answers on the dispatched data, so that we can reference them again
            const data = { ...this.post, existingAnswer: this.selectedAnswers };
            this.$store.commit("setCurrentlyViewedPost", { data })
            this.$store.dispatch("openModal", { id: "view-full-post" });
        },
    },
}
