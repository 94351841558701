
import _ from 'lodash';

export default {
  name: 'GroupBadge',
  props: ['post'],
  computed: {
    badge() {
      return (
        _.get(this.post, 'author.membership.role') === 'LEADER' &&
        _.get(this.post, 'author.membership.group', 'nogroup') === _.get(this.post, 'groups[0]._id', '')
      );
    },
  },
};
