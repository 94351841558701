
import Vue from 'vue';
import HomeFeedContainer from '@/feed/HomeFeedContainer/index.vue';
import { staticPath } from '@/data/helpers';

export default Vue.extend({
  name: 'HomePage',
  components: {
    HomeFeedContainer,
  },
  head() {
    return {
        link: [
          {
            rel: "preload",
            href: staticPath("js/dfpv2_1.js"),
            as: "script",
          },
        ],
        script: [
          {
            async: true,
            src: staticPath("js/dfpv2_1.js"),
          },
        ],
      }
  },
});
