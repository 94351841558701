
/* eslint no-console:0 */
// libs

export default {
  name: 'MaybeLink',
  props: {
    when: {
      default: false,
    },
    innerHtml: {
      default: '',
    },
    href: {
      default: '',
    },
  },
};

