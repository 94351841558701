
import vue from 'vue';

import CommunityHighlights from '@/userExperienceRedesign/modules/CommunityHighlights/index.vue';
import CommunityStory from '@/userExperienceRedesign/modules/CommunityStory/index.vue';
import CommunityVideoStory from '@/userExperienceRedesign/modules/CommunityVideoStory/index.vue';
import Events from '@/userExperienceRedesign/modules/Events/index.vue';
import Newsletter from '@/userExperienceRedesign/modules/Newsletter/index.vue';
import SponsoredStory from '@/userExperienceRedesign/modules/SponsoredStory/index.vue';
import Spotlight from '@/userExperienceRedesign/modules/Spotlight/index.vue';

import CommunityVideoStoryController from '@/userExperienceRedesign/controllers/communityVideoStory';
import CommunityStoryController from '@/userExperienceRedesign/controllers/communityStory';
import CommunityHighlightsController from '@/userExperienceRedesign/controllers/communityHighlights';
import EventController from '@/userExperienceRedesign/controllers/event';
import NewsletterController from '@/userExperienceRedesign/controllers/newsletter';
import SpotlightController from '@/userExperienceRedesign/controllers/spotlight';
import { isStory, isCommunityHighlight, isEvent, isNewsletter, isSponsoredStory, isSpotlight, isCommunityVideo } from '@/userExperienceRedesign/helpers';


export default vue.extend({
  name: 'FeedCardRedesign',
  components: {
    CommunityHighlights,
    CommunityStory,
    CommunityVideoStory,
    Events,
    Newsletter,
    SponsoredStory,
    Spotlight,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({
        humanNumber: true,
        humanNumberFirstValue: true,
        analyticsEventData: {},
        utmCampaignPrefix: '',
      }),
    },
    gaCategory: {
      default: '',
    },
    segmentData: {
      type: Object,
      required: true,
    },
    cameFrom: {
      default: ''
    },
  },
  computed: {
    componentProps() {
      if (!this.post) return undefined;

      if (isStory(this.post) || isSponsoredStory(this.post)) {
        return new CommunityStoryController(this.post).value;
      }

      if (isCommunityHighlight(this.post)) {
        return new CommunityHighlightsController(this.post).value;
      }

      if (isEvent(this.post)) {
        return new EventController(this.post).value;
      }

      if (isNewsletter(this.post)) {
        return new NewsletterController(this.post).value;
      }

      if (isCommunityVideo(this.post)) {
        return new CommunityVideoStoryController(this.post).value;
      }

      if (isSpotlight(this.post)) {
        return new SpotlightController(this.post).value;
      }

      return undefined;
    },
    isStory() {
      return isStory(this.post);
    },

    isCommunityVideo() {
      return isCommunityVideo(this.post);
    },

    isCommunityHighlight() {
      return isCommunityHighlight(this.post);
    },
    isEvent() {
      return isEvent(this.post);
    },
    isNewsletter() {
      return isNewsletter(this.post);
    },
    isSponsoredStory() {
      return isSponsoredStory(this.post);
    },
    isSpotlight() {
      return isSpotlight(this.post);
    },
  },
});
