
import vue from 'vue';
import { newsLetterModuleProps } from '../types';
import OutlinedButton from '@/userExperienceRedesign/components/OutlinedButton/index.vue';
import CollaborationsIcon from '@/userExperienceRedesign/components/Icon/newsLetterCollaborationsIcon.vue';

export default vue.extend({
  name: 'NewsletterCollaborations',
  components: { OutlinedButton, CollaborationsIcon },
  props: newsLetterModuleProps,
});
