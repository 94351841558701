

import { mapGetters } from 'vuex';
import { humanNumberFormatWithOptions, staticPath } from '@/data/helpers';
import { heartStructure } from '@/shared/utils/segmentData';
import LazyImage from '@/components/LazyImage';

export default {
  name: 'PostHeartsButton',
  props: ['post', 'options', 'gaCategory', 'segmentData'],
  data() {
    return {
      // using this to disable continuous hearting/unhearting
      canHeart: true,
    };
  },
  components: {
    LazyImage
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    action_counts() {
      return this.post.action_counts || {};
    },
    // number of post hearts
    hearts() {
      return this.action_counts.hearts || 0;
    },
    fb_engagement_cnt() {
      return this.action_counts.fb_engagement_cnt || 0;
    },
    post_id() {
      if (this.post.type && this.post.type.indexOf('WORDPRESS') > -1) {
        return parseInt(this.post._id, 10);
      }
      return this.post.id;
    },

    // current user hearted this post?
    hearted() {
      return this.post.hearted || this.$store.getters.userHeartedPost(this.post._id);
    },
    heartsCount() {
      return humanNumberFormatWithOptions(this.hearts + this.fb_engagement_cnt, 'Be first', this.options);
    },
    heartsDisplay() {
      // if the hearts count is less than 0, return 0 (so not to show negative numbers), otherwise show the heart count
      return typeof this.heartsCount === 'number' && this.heartsCount < 0 ? 0 : this.heartsCount;
    },
  },
  methods: {
    staticPath,
    togglePostHeart() {
      if (!this.canHeart) {
        return;
      }
      this.canHeart = false;
      const segmentData = {
        ...heartStructure(this.post, this.user, this.hearted ? 'Unheart' : 'Heart'),
      };

      segmentData.context = {
        ...segmentData.context,
        ...this.segmentData.context,
      }

      this.$store.dispatch('toggleUserHeartedPost', {
        postId: this.post._id,
        post: this.post,
        options: this.options,
        gaCategory: this.gaCategory,
        userId: this.user.id,
        segmentData,
      }).then(() => { this.canHeart = true; });
    },
    humanNumberFormatWithOptions,
  },
};
