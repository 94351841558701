

export default {
  name: 'MenuPane',
  components: { },
  props: {
    content: {
      default: '',
    },
  },
};
