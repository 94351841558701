
import { PropType } from 'vue';
import { PeopleAreTalkingAboutThisProps } from './types';
import AvatarGroup from '../AvatarGroup/index.vue';

export default {
  name: 'PeopleAreTalkingAboutThis',
  components: { AvatarGroup },
  props: {
    /**
     * An array of avatar URL strings for the people who reacted to the post
     */
    avatarGroup: {
      type: Array as PropType<PeopleAreTalkingAboutThisProps['avatarGroup']>,
    },

    /**
     * Text to display next to the avatar group of people who reacted
     */
    blurb: {
      type: String as PropType<PeopleAreTalkingAboutThisProps['blurb']>,
    },

    /**
     * The number of people who have reacted to the story.
     */
    reactionsCount: {
      type: Number as PropType<PeopleAreTalkingAboutThisProps['reactionsCount']>,
    },
  },
};
