
import _ from 'lodash';
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import { staticPath } from '@/data/helpers';

export default {
    name: 'CardStats',
    props: ['index', 'post', 'options', 'mode', 'gaCategory', 'segmentData', 'menuOpened'],
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            userReactions: 'userReactions',
        }),
        postId() {
            return this.post._id || this.post.id;
        },
        commentsCount() {
            return _.get(this.post, 'action_counts.comments', 0);
        },
        reactionActionCounts() {
            const counts = _.assign({}, _.get(this.post, 'action_counts.reactions', {}));

            // manipulating action counts to account for any session reactions
            const hasUserReaction = _.find(this.userReactions, r => r.postId === this.postId);
            if (hasUserReaction) {
                const originalReaction = _.get(this.post, 'reaction_type');
                const newReaction = hasUserReaction.reaction;

                // if no difference between reactions, no need for adjustment
                if ((newReaction === null && originalReaction === null)
                    || (newReaction === originalReaction)) {
                    return counts;
                }

                // otherwise add new reaction to count and remove original reaction from count
                if (newReaction) {
                    counts[newReaction.toLowerCase()] += 1;
                }

                if (originalReaction) {
                    counts[originalReaction.toLowerCase()] -= 1;
                }
            }

            return counts;
        },
        reactionsTotalCount() {
            return _.sum(_.values(this.reactionActionCounts));
        },
        reactions() {
            const reactions = [
                { field: 'cheer', imgSrc: 'balloons' },
                { field: 'energy', imgSrc: 'battery' },
                { field: 'helpful', imgSrc: 'bulb' },
                { field: 'been_there', imgSrc: 'hand' },
                { field: 'heart', imgSrc: 'heart' },
                { field: 'not_alone', imgSrc: 'sun' }
            ];
            _.forEach(reactions, reaction => {
                reaction.count = this.reactionActionCounts[reaction.field] || 0;
            });
            return reactions.sort((a, b) => b.count - a.count);
        },
        previewedReactions() {
            return _.filter(this.reactions, reaction => reaction.count > 0).slice(0, 3);
        },
    },
    methods: {
        staticPath,
        toggleMenu() {
            this.$emit('toggleMenu');
        },
        closeMenu(e) {
            if (this.menuOpened) {
                this.toggleMenu();
            }
        }
    }
}
