
/* eslint-disable max-len */
import Vue, { PropType } from 'vue';
import segmentAnalytics from '@/libs/events';
import OutlinedButton from '../../components/OutlinedButton/index.vue';
import CommunityLabel from '../../components/CommunityLabels/CommunityLabel.vue';
import LikeShareButtonGroup from '../../components/LikeShareButtons/LikeShareButtonGroup.vue';
import { EventsPropsI } from './types';
import CalendarIcon from '@/userExperienceRedesign/components/Icon/eventStarIcon.vue';
import LazyImage from '@/components/LazyImage/index.vue';

export default Vue.extend({
  name: 'Events',
  components: {
    OutlinedButton,
    LikeShareButtonGroup,
    CommunityLabel,
    CalendarIcon,
    LazyImage,
  },
  props: {
    /**
     * Data for event
     */

    // NOTE: Where to use eventUrl? With CTA?
    eventInfo: {
      type: Object as PropType<EventsPropsI['eventInfo']>,
    },

    withHostInfo: {
      type: Boolean,
      default: true,
    },
    /**
     * Data for how the category should be labeled once dynamic
     */
    category: {
      type: Object as PropType<EventsPropsI['category']>,
    },
    /**
     * Asks if event is virtual || physical, will affect ctaText
     */
    inPerson: {
      type: Boolean,
      default: true,
    },
    post: {
      type: Object,
      required: true,
    },
    index: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  computed: {
    ctaLabel() {
      return 'Save Your Spot';
    },
    eventType() {
      if (this.inPerson) {
        return "You're Invited!";
      }
      return 'Free Virtual Event!';
    },
    updateMarginsFunction() {
      if (this.inPerson) {
        return { marginBottom: '2rem' };
      }
      return '';
    },
    eventIconPlacement() {
      if (this.inPerson === null || undefined) {
        return { display: 'flex', justifyContent: 'flex-end', width: '100%' };
      }
      return '';
    },
  },
  methods: {
    onClick() {
      const { ctaLabel, eventInfo: { eventUrl }, post, index } = this;
      const user = post.author;
      segmentAnalytics.choose({
        path: eventUrl,
        post: {
          id: post.id,
          title: post.title,
          topic: {
            ids: (post.topics || []).map((t: {id: string}) => t.id),
            names: (post.topics || []).map((t: {title: string}) => t.title),
          },
          user: {
            id: user.id,
            username: user.username,
          },
          type: post.type,
          pinned: post.isPinned,
        },
        context: {
          module: 'NLM_Event',
          path: window.location.pathname,
          card: {
            cta: {
              text: ctaLabel,
            },
            index,
          },
          view: 'topic_feed',
        },
        target: {
          name: 'module_cta',
          type: 'button',
        },
      });
    },
  },
});
