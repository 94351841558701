
/* eslint no-underscore-dangle:0 */
import _ from "lodash";
import { mapGetters } from "vuex";
import NormalizeAuthor from "@/mixins/normalize-author";
import MoreLess from "@/mixins/more-less";
import Notification from "@/components/Notification";
import FeedCardActionMenu from "@/feed/FeedCard/FeedCardActionMenu";
import {
  log,
  isSet,
  sameDomainUrl,
  selectorMatches,
  doesUserOwnPost,
} from "@/data/helpers";
import CardFooter from "@/feed/FeedCard/CardFooter";
import CardComment from "@/feed/CardComment";
import ContentCard from "../ContentCard";
import CardByline from "../FeedCard/CardBylineSmall";
import PollContent from "./PollContent";

const cardElementsAllowedFocus =
  "a, .action-menu, .card-share-menu, .feed-card.sharing, .jwplayer, input, textarea, button, .menu-item a";

export default {
  name: "PollCard",
  mixins: [MoreLess, NormalizeAuthor],
  components: {
    FeedCardActionMenu,
    ContentCard,
    CardByline,
    CardFooter,
    Notification,
    CardComment,
    PollContent,
  },

  // passed in properties
  props: {
    feedName: {
      default: "",
    },
    post: {
      type: Object,
      required: true,
    },
    options: {
      humanNumber: true,
      humanNumberFirstValue: true,
      analyticsEventData: {},
    },
    gaCategory: {
      default: "",
    },
    index: undefined,
    segmentData: undefined,
  },
  data() {
    return {
      maxHeight: 48,
      alreadySubmitted: false,
      selectedAnswer: null,
      showEarlyResults: false,
      canToggleEarlyResults: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),

    showContent() {
      return this.post.status.type !== "REJECTED";
    },

    // does this user own this item
    ownsItem() {
      return doesUserOwnPost(this.user, this.post);
    },
    // on a user's profile page (as owner), displaying the user's comment below the Post Card
    hasCommentToShow() {
      return this.post.comment && this.post.comment._id;
    },
  },

  // handle events and such
  methods: {
    // handleWasSubmitted(newComment) {
    handleWasSubmitted() {
      this.alreadySubmitted = sameDomainUrl(
        `/#post=${this.post.id}&comments=1`
      );
    },

    // getting the selected answer from the Poll Content, to pass onto any other place that may need it
    updateSelectedAnswer(val) {
      this.selectedAnswer = val;
    },

    /**
     * Determines if the given card element accepts focus
     * Some elements (defined in `cardElementsAllowedFocus`)
     *   can also have the focus in front of the card itself
     */
    isCardElementFocusAllowed(element) {
      return (
        selectorMatches(element, cardElementsAllowedFocus) ||
        (element.closest(cardElementsAllowedFocus) || []).length > 0
      );
    },

    /**
     * Opens the card link when any place in card is clicked (not sharing)
     * The redirection only happens for clicks in non actionable elements
     */
    openCardLink(e) {
      if (
        _.findIndex(e.path, (el) => {
          return _.includes(el.classList, "poll-content-wrap");
        }) > -1
      ) {
        return;
      }
      if (_.get(e, "target.parentElement.className") === "link-preview") {
        // if click on link Preview, don't open full post
        return;
      }

      if (
        !this.isCardElementFocusAllowed(e.target) &&
        this.post.status.type !== "REJECTED"
      ) {
        this.$refs["poll-content"].openFullPost();
      }
    },
  },
};
