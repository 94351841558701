
// import googletag from 'googletag';
import _ from "lodash";
import { staticPath } from "@/data/helpers";

/**
 * A generic adslot with default layout included
 */
const uniqId = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export default {
  name: "AdSlot",
  props: {
    id: {
      default: () => 'id_' + uniqId(),
    },
    unit: {
      required: true,
    },
    includeLayout: {
      default: true,
    },
    showAdDisclosure: {
      default: true,
    },
    targeting: {
      required: false,
    },
    unitFallback: {
      required: false,
    },
    size: {
      required: true,
    },
    canClose: {
      default: false,
    },
  },
  data() {
    return {
      isEmpty: false,
      rendered: false,
      ssr: true,
    };
  },
  methods: {
    staticPath,
    slotRendered(isEmpty) {
      this.isEmpty = isEmpty;
      this.rendered = true;
      this.$emit("rendered", isEmpty);
    },
    closeAd() {
      this.isEmpty = true;
    },
    refreshAdSlot() {
      const { requestedAds  } = window.dfp || {};

      const iframe = this.$el.querySelector("iframe");
      if(!requestedAds[this.id].isEmpty && !iframe) {
        window.googletag.cmd.push(() => {
          googletag.pubads().refresh([requestedAds[this.id].slot]);
        });
      }
    },
    requestAlreadyMade() {
      const { requestedAds = {}, emptyAds = [] } = window.dfp || {};
      if(requestedAds[this.id]) {
        this.isEmpty = requestedAds[this.id].isEmpty
        this.slotRendered(requestedAds[this.id].isEmpty);
        this.refreshAdSlot();
        return true
      }
      return emptyAds.includes(this.id)
    },
    waitForRequest() {
      if(!this.requestAlreadyMade()) {
        setTimeout(this.waitForRequest, 50);
      }
    },
    waitForAdLibrary() {
      const { requestedAds  } = window.dfp || {};
      if(!requestedAds) {
        setTimeout(this.waitForAdLibrary, 50);
      } else {
        this.displayAdSlot();
      }
    },
    displayAdSlot() {
      const { requestedAds  } = window.dfp || {};
      if(requestedAds && requestedAds[this.id]) {
        this.slotRendered(requestedAds[this.id].isEmpty);
      } else if(requestedAds && window.dfp){
        window.dfp.displayAd({
          dfpId: process.env.DFP_ID,
          adUnit: this.unit,
          id: this.id,
          size: this.size,
          targeting: this.targeting,
        })
      }
      // if the ad slot is visible, check if the request has already been made, 
      const observer = new IntersectionObserver((entries) => {
        if (entries.some(el => el.isIntersecting)) {
          observer.disconnect();
          this.waitForRequest();    
        }
      });
      observer.observe(this.$el);
    }
  },
  mounted() {
    this.waitForAdLibrary();
  },
  beforeDestroy() {
    const { slotRequested = {}, destroyAdSlots  } = window.dfp || {};
    
    if(!slotRequested[this.id] && destroyAdSlots) {
      destroyAdSlots([this.id]);
    }
  },
  fetch() {
    if (process.client) {
      this.ssr = false;
    }
  },
  computed: {
    containerClasses() {
      const classes = [];
      if (this.size) {
        classes.push(this.displaySize.join("x"));
      }
      if (this.isEmpty) {
        classes.push("empty");
      }
      if(!this.showAdDisclosure) {
        classes.push("hide-ad-disclosure");
      }
      return classes;
    },
    displayStyles() {
      const styles = {};
      if (this.size && !this.isFluid && this.unit !== "1x1_custom") {
        styles.width = `${this.displaySize[0]}px`;
        styles.height = `${this.displaySize[1]}px`;
      }
      return styles;
    },
    displaySize() {
      const isMultiSize = _.isArray(_.head(this.size));
      if (this.rendered && isMultiSize && this.$el) {
        const iframe = this.$el.querySelector("iframe");
        if (iframe) {
          return [
            iframe.attributes.width.value,
            iframe.attributes.height.value,
          ];
        }
      }
      if (isMultiSize) {
        return _.head(this.size);
      }
      return this.size;
    },
    isFluid() {
      return this.size.indexOf("fluid") > -1;
    },
  },
};
