import { isSet, isObject } from '@/data/helpers';

let savedScrollTop = 0;
// get the scroll position
function getWindowPosition() {
  const doc = document.documentElement;
  return {
    left: (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0),
    top: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0),
  };
}

// set the scroll position
function setWindowPosition(top, left) {
  window.scrollTo(left, top);
}

// get the body
function getBody() {
  return document.getElementsByTagName('BODY')[0] || null;
}

// get the first matching element
function getFirstElement(selector) {
  // if already a node, then use it
  if (isObject(selector) && isSet(selector.nodeType)) {
    return selector;
  }

  let els = [];
  // otherwise find the first matching element
  if (typeof document.querySelector === 'function') {
    els = document.querySelector(selector);
  }
  return Array.isArray(els) ? els.shift() : null;
}

// safari lock
function preventMobileSafariScrolling(e) {
  // log('preventing mobile scroll');
  if (e) {
    e.preventDefault();
  }
}

// safari unlokc
function enableMobileSafariScrolling(e) {
  // log('ENABLING WITHIN preventing mobile scroll');
  if (e) {
    e.cancelBubble = true;
  }
  return true;
}

// allow scrolling on an element
function allowScrolling(ele) {
  // log('allowScrolling');
  ele.addEventListener('touchmove', enableMobileSafariScrolling, false);
  ele.addEventListener('mousewheel', enableMobileSafariScrolling, false);
}

// undo allow scrolling on an element
function removeAllowScrolling(ele) {
  // log('removeAllowScrolling');
  ele.removeEventListener('touchmove', enableMobileSafariScrolling, false);
  ele.removeEventListener('mousewheel', enableMobileSafariScrolling, false);
}

// prevent scrolling on an element
function preventScrolling(ele) {
  // log('preventScrolling');
  ele.addEventListener('touchmove', preventMobileSafariScrolling, false);
  ele.addEventListener('mousewheel', preventMobileSafariScrolling, false);
}

// remove the prevention of scrolling on an element
function removePreventScrolling(ele) {
  // log('removePreventScrolling');
  ele.removeEventListener('touchmove', preventMobileSafariScrolling, false);
  ele.removeEventListener('mousewheel', preventMobileSafariScrolling, false);
}

// lock scrolling on the current page position
function lockScroll(allowFor) {
  savedScrollTop = getWindowPosition().top;
  const body = getBody();
  // const sty = getAttr(body, 'style');
  // setAttr(body, 'style', `${sty}overflow-y:hidden;`);
  window.bodyScrollDistance = window.scrollY;
  body.style['overflow-y'] = 'hidden';
  if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
    body.style.position = 'fixed';
  }
  if (document && document.body && typeof document.body.addEventListener === 'function') {
    preventScrolling(document.body);

    let i;
    if (Array.isArray(allowFor)) {
      for (i = 0; i < allowFor.length; i += 1) {
        const innerEle = getFirstElement(allowFor[i]);
        if (innerEle) {
          allowScrolling(innerEle);
        }
      }
    }
  }
}

// unlock the scroll
function unlockScroll(allowFor) {
  const body = getBody();
  // const sty = getAttr(body, 'style');
  // setAttr(body, 'style', sty.replace(/overflow-y:hidden/g, ''));
  body.style['overflow-y'] = '';
  body.style.position = '';
  window.scrollTo(0, window.bodyScrollDistance);
  setWindowPosition(savedScrollTop);
  if (document && document.body && typeof document.body.addEventListener === 'function') {
    removePreventScrolling(document.body);

    let i;
    if (Array.isArray(allowFor)) {
      for (i = 0; i < allowFor.length; i += 1) {
        const innerEle = getFirstElement(allowFor[i]);
        if (innerEle) {
          removeAllowScrolling(innerEle);
        }
      }
    }
  }
}

export default {
  getWindowPosition,
  setWindowPosition,
  getFirstElement,
  allowScrolling,
  removeAllowScrolling,
  preventScrolling,
  removePreventScrolling,
  lockScroll,
  unlockScroll,
};
