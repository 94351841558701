
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { staticPath } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';
import BookmarkIcon from './BookmarkIcon';

export default {
  name: 'PostBookmarkButton',
  components: { BookmarkIcon, },
  props: ['post', 'options', 'gaCategory', 'segmentData', 'placement'],
  data() {
    return {
      bookmarkHover: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getMygroups: 'getMygroups',
      userProfileBookmarkedPost: 'userProfileBookmarkedPost',
    }),
    savedToProfile() {
      // using bookmarks in the store as well, as not all bookmark actions modify the post prop (ie. if the feed card is open)
      return _.get(this.post, 'bookmarked', 0) || this.$store.getters.userProfileBookmarkedPost(this.post._id);
    },
    sessionCommunityBookmarks() {
      // similar to profile, using bookmarks in the store as well, as not all bookmark actions modify the post prop (ie. if the feed card is open)
      const postBookmarks = this.$store.getters.userCommunityPostBookmarks(this.post._id);
      if (postBookmarks) {
        return postBookmarks.communities;
      }
      return [];
    },
    communityBookmarks() {
      // union concats arrays while removing duplicates
      return _.union(_.get(this.post, 'bookmarked_to_groups', []), this.sessionCommunityBookmarks);
    },
    bookmarked() {
      return this.savedToProfile || this.communityBookmarks.length > 0;
    },
    bookmarkedWithHover() {
      return this.bookmarked || this.bookmarkHover;
    },
    storyEngagementbar() {
      return this.placement === 'story-engagement-bar';
    },
    buttonClass() {
      if (this.storyEngagementbar) {
        return 'bookmark-engagement-bar-wrapper';
      }
      return '';
    },
  },
  methods: {
    staticPath,
    addProfileBookmark() {
      this.$store.dispatch('addProfileBookmark', { post: this.post });
    },
    removeProfileBookmark() {
      const callback = () => { this.$store.dispatch('removeProfileBookmark', { post: this.post }); };
      this.$store.dispatch('openModal', {
        id: 'confirm-unsave',
        args: { callback },
      });
    },
    async bookmarkPost() {
      if (!this.user._id) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }

      const postCommunityId = _.get(this.post, 'groups[0]._id');

      // check if post belongs to group, if the user is part of that group, and then next condition check whether that group is already loaded on state
      if (postCommunityId && !_.some(_.filter(this.getMygroups, g => g.role !== 'MEMBER_DEFERRED_JOIN'), group => group._id.toString() === postCommunityId)) {
        this.promptToJoin();
      } else {
        this.bookmarkAction();

        const context = {
          view: this.segmentData.context.view,
          section: this.segmentData.context.section,
        };

        const author = this.post.author || this.post.author_id;

        if (this.post.groups.length) {
          context.group = {
            id: this.post.groups[0].id,
            name: this.post.groups[0].name,
          };
        }

        segmentAnalytics.save({
          context,
          post: {
            id: this.post.id,
            type: this.post.type,
            title: this.post.title,
            topic: {
              names: (this.post.topics || []).map(topic => topic.title),
            },
            ...this.post.type === 'WORDPRESS_POST' && Array.isArray(this.post.topics) ? {
              topic0: this.post.topics && this.post.topics[0],
              primary_topic: this.post?.card_data?.['card-author-byline'],
            } : {},
            user: {
              username: author.username,
              id: author.id,
            },
          },
          userId: this.user._id,
          sessionId: this.user.sessionId,
        });
      }
    },
    bookmarkAction() {
      if (_.some(this.getMygroups, group => group.role === 'LEADER')) {
        this.$store.dispatch('openModal', {
          id: 'post-bookmark',
          args: { post: this.post, savedToProfile: this.savedToProfile, communityBookmarks: this.communityBookmarks },
        });
      } else if (this.bookmarked) {
        this.removeProfileBookmark();
      } else {
        this.addProfileBookmark();
      }
    },
    promptToJoin() {
      this.$store.dispatch('openModal', {
        id: 'join-to-action',
        args: { group: _.get(this.post, 'groups[0]'), action: 'save', callback: this.bookmarkAction, page: this.segmentData.context.view, section: this.segmentData.context.section },
      });
    },
  },
};
