
import _ from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";
import $ from "jquery";
import { isSet, isObject, getPostCardType } from "@/data/helpers";
import { cleanHTML, sanitizeText } from "@/data/card-helpers";
import FeedCardRedesign from "@/userExperienceRedesign/modules/FeedCardRedesign";
import AdSlot from "@/components/Ads/AdSlot.vue";
import InviewMixin from "@/plugins/InviewMixin";
import { triggerViewPost } from "@/libs/events";
import {
  isEvent,
  isNewsletter,
  isSpotlight,
} from "@/userExperienceRedesign/helpers";
import StoryCard from "../StoryCard";
import PostCard from "../PostCard";
import PollCard from "../PollCard";

export default {
  name: "FeedCard",
  mixins: [InviewMixin],
  components: {
    StoryCard,
    PostCard,
    PollCard,
    FeedCardRedesign,
    AdSlot,
},
  props: {
    feedName: {
      default: "",
    },
    post: {
      type: Object,
      required: true,
    },
    // this will be for coming from the CollectionModal
    collection: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    size: {
      default: "60px",
    },
    options: {
      type: Object,
      default: () => ({
        humanNumber: true,
        humanNumberFirstValue: true,
        analyticsEventData: {},
        utmCampaignPrefix: "",
      }),
    },
    gaCategory: {
      default: "",
    },
    index: undefined,
    view: undefined,
    section: undefined,
    parentStory: undefined,
    showAds: {
      default: false,
    },
    feedTotalItems: {
      default: 0,
    },
    dfpUnit: {
      required: false,
    },
    dfpUnitV2: {
      default: "Ad_unit_feed"
    },
    redesignModule: {
      default: false,
    },
    cameFrom: {
      default: "",
    },
    targeting: {
      type: Object,
      default() {
          return {}
      }
    },
  },

  mounted() {
    // @NOTE - remove this once we connect the data that contains it
    // eslint-disable-next-line vue/no-mutating-props
    this.post.type =
      isSet(this.post.type) && this.post.type && this.post.type.length
        ? this.post.type
        : "story";
    $(this.$el).data("post-data", this.post.card_data);
  },
  methods: {
    handleElementInview() {
      // not triggering for FeaturedFeed
      if (this.post.editorPick) {
        return;
      }

      this.$store.commit("triggerCardInview", {
        gaCategory: this.gaCategory,
        post: this.post,
        options: this.options,
      });
      const {
        post,
        segmentData: { context },
      } = this;

      if (this.cameFrom) {
        context.section = `hubs_${this.cameFrom.toLowerCase()}`;
      }

      triggerViewPost(post, context, this.user._id, this.user.sessionId || '');
    },
  },

  // properties that require calculation
  computed: {
    ...mapGetters({
      user: "user",
    }),
    userLoggedIn() {
      return isObject(this.user) && isSet(this.user.username);
    },
    redesignedCard() {
      return isEvent(this.post) || isNewsletter(this.post) || isSpotlight(this.post) || this.isVideo;
    },
    // card type string
    cardType() {
      // check the post first
      if (isObject(this.post) && this.post.type && this.post.type.length) {
        return this.post.type;
      }

      // otherwise check the passed in type, if any
      if (this.type && this.type.length) {
        return this.type;
      }

      // default to story
      return "WORDPRESS_POST";
    },

    feedCardOptions() {
      const cardType = getPostCardType(this.post);
      return Object.assign({}, this.options, {
        utmCampaignPrefix: `${this.options.utmCampaignPrefix}.${cardType}_preview_card`,
      });
    },

    isStory() {
      return ["WORDPRESS_POST", "WORDPRESS_VIDEO", "story"].includes(
        this.cardType
      );
    },

    // is this a thought
    isVideo() {
      return this.cardType === "WORDPRESS_VIDEO";
    },

    saneTitle() {
      const { title, topics, mentions, embeds } = this.post;
      return sanitizeText({ content: title, topics, mentions, embeds });
    },

    saneBody() {
      const { body, topics, mentions, embeds } = this.post;
      return sanitizeText({ content: cleanHTML(body || ''), topics, mentions, embeds });
    },

    stripHtmlTitle() {
      return this.saneTitle.replace(/<(.|\n)*?>/gi, "");
    },

    stripHtmlBody() {
      return this.saneBody.replace(/<(.|\n)*?>/gi, "");
    },

    segmentData() {
      const data = {
        context: {
          view: this.view,
          card: {
            content_id: this.post.id,
            index: this.index,
            title: this.stripHtmlTitle,
            body: this.stripHtmlBody,
            type: "preview",
          },
          // section: this.section === 'featured_story' ? 'hubs_featured' : '',
          section: this.section !== 'hubs_resources' ? (this.section === 'featured_story' ? 'hubs_featured' : '') : 'hubs_resources',
        },
        target: {
          id: this.post.id || this.post._id,
          name: this.stripHtmlTitle,
          type: _.get(this.post, "type", "").toLowerCase(),
        },
        userId: this.user._id,
        sessionId: this.user.sessionId,
      };

      // in the event it's from a click within the CollectionModal
      if (this.collection.id) {
        delete data.context.card;
        delete data.context.section;
        data.context.collection = this.collection;
      }

      if (this.parentStory) {
        data.post = {
          id: this.parentStory.id,
          title: this.parentStory.title,
          type: "WORDPRESS_POST",
          topic: {
            names: _.get(this.parentStory, "topics", []).map(
              (topic) => topic.title
            ),
            ids: _.get(this.parentStory, "topics", []).map((topic) => topic.id),
          },
          user: {
            id: _.get(this.parentStory, "author_id.id"),
            username: _.get(this.parentStory, "author_id.username"),
            displayname: _.get(this.parentStory, "author_id.username"),
            type: _.get(this.parentStory, "author_id.role_slug"),
          },
        };
      }
      return data;
    },
    shouldDisplayAd() {
      const cardPos = this.index + 1;
      const hasPosition = cardPos % 3 === 0;
      const smallFeed = this.feedTotalItems < 3;
      const lastPosition = cardPos === this.feedTotalItems;
      return this.showAds && (hasPosition || (smallFeed && lastPosition));
    },
  },
};
