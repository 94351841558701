import { AuthorI } from '../types/author';
import { get, isObject } from 'lodash';

export default class AuthorController {
  protected data?: AuthorI;

  constructor(post: any) {
    this.parse(post);
  }
  public get value() {
    return this.data;
  }

  protected parse(post: any) {
    const author = isObject(post.author_id) ? post.author_id : post.author;
    this.data = {
      post,
      authorImage: get(author, 'profile.avatar_thumbnail'),
      authorName: author.display_name,
      authorTitle: get(author, 'profile.role'),
      authorUrl: `/u/${author.username}/`,
      authorOrganization: '', // TODO
      authorOrganizationUrl: '', // TODO
      authorHandle: author.username, // TODO
      authorId: author._id,
    };
  }
}
