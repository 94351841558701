
import _ from 'lodash';
import { cleanHTML, sanitizeText } from '@/data/card-helpers';
import { siteUrl } from "@/data/helpers";


// this is for the bottom of a FeedCard, mostly for Profile pages, where we show the user their specific comment
export default {
  name: 'CardComment',
  data() {
    return {}; 
  },
  props: ['comment'],
  computed: {
    username() {
      return _.get(this.comment, 'author_id.username', '');
    },
    displayName() {
      return _.get(this.comment, 'author_id.display_name', '');
    },
    avatar() {
      return _.get(this.comment, 'author_id.profile.avatar_thumbnail', '');
    },
    commentContent() {
      const { body, embeds, mentions } = this.comment;
      return sanitizeText({ content: cleanHTML(body), mentions, embeds });
    },
  },
  methods: {
    mentionLinkClick(e) {
      if (e.target && e.target.href && e.target.classList.contains('tm-mention-link')) {
        this.$router.push(siteUrl(e.target.href, true));
      }
    },
  },
};
