
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from 'uuid';
import FeedCard from "@/feed/FeedCard";
import FeedLoading from "@/feed/FeedLoading";
import FeaturedFeed from '@/feed/FeaturedFeed/index.vue';
import FeaturedCollections from '@/feed/FeaturedCollections';
import ModuleFeature from '@/components/ModuleFeature';
import SeeMoreButton from "./SeeMoreButton";

export default {
  name: "BaseFeed",
  // we use these components inside this component
  components: {
    SeeMoreButton,
    FeedCard,
    FeedLoading,
    FeaturedFeed,
    FeaturedCollections,
    ModuleFeature,
  },

  // these are passed in from elsewhere
  props: {
    showSeeMore: {
      default: true,
    },
    hideIfEmpty: {
      default: false,
    },
    size: {
      default: "60px",
    },
    name: {
      default: "",
    },
    parentFeed: {
      default: null,
    },
    cardOptions: {
      type: Object,
      default: () => ({
        humanNumber: true,
        humanNumberFirstValue: false,
        utmCampaignPrefix: "feed",
      }),
    },
    limit: {
      default: 10,
    },
    gaCategory: {
      default: "",
    },
    view: undefined,
    trending: {
      default: true,
    },
    types: {
      default: null,
    },
    visible: {
      default: false,
    },
    adUnit: {
      default: null,
    },
    adUnitV2: {
      required: false
    }
  },
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    // on visible, check to see if Feed items need to be loaded
    // basically for the first time the user clicks on that tab
    visible: {
      immediate: true,
      async handler(val) {
        if (val && !this.initialFeed) {
          if (this.getFeedItems(this.name).length === 0) {
            this.loading = true;
            const args = {
              feedName: this.name,
              limit: Number(this.limit),
              types: this.types,
              latest: this.name === 'posts',
            };
            await this.$store.dispatch("loadFeedItems", args).then(() => {
              this.loading = false;
              });
          } else {
            this.loading = false;
          }
        }
      },
    }
  },

  computed: {
    // load data from the global state handler
    ...mapGetters({
      user: "user",
      getFeedItems: "getFeedItems",
      getFeedOffset: "getFeedOffset",
      isInitialLoadCompleted: 'isInitialLoadCompleted',
      getModuleSettings: "getModuleSettings",
    }),

    // get this feed's items
    myFeedItems() {
      return this.getFeedItems(this.name);
    },

    // items offset
    itemOffset() {
      return this.getFeedOffset(this.name);
    },

    useNewAdLogic() {
      return true;
    },
    initialFeed() {
      // personalized and featured feeds are part of initial load, so no need to re-load data from BaseFeed
      return ['personalized', 'featured'].includes(this.name);
    },
    feedLoading() {
      if (this.initialFeed) {
        return !this.isInitialLoadCompleted;
      }
      
      return this.loading;
    },
    showModule() {
      return this.getModuleSettings.enabled && this.getModuleSettings.current && this.getModuleSettings.current.id;
    },
  },
};

/**
 * @NOTE - v-if on each modal below makes it so that when the modal becomes active, the modal's mounted() function is called. otherwise, you cannot distinguish
 *         modal creation from modal data update
 */
