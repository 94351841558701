
/* eslint no-underscore-dangle:0 */
// libs
import { mapGetters } from 'vuex';

// sibling components
import { isSet, isObject } from '@/data/helpers';
import MenuDots from './MenuDots';
import MenuPane from './MenuPane';

export default {
  name: 'CommentMenu',
  props: {
    item: {
      default: null,
    },
    menuContent: {
      default: '',
    },
  },
  components: { MenuDots, MenuPane },
  computed: {
    ...mapGetters({
      menuOpenItemId: 'menuOpenFor',
    }),
    itemId() {
      if (isObject(this.item) && (isSet(this.item._id) || isSet(this.item.id))) {
        if (isSet(this.item._id)) {
          return this.item._id;
        }
        return this.item.id;
      }
      return this.$vId;
    },
  },
  watch: {
    menuOpenItemId() {
      if (this.menuOpenItemId === this.itemId) {
        this.$refs.menu.focus();
      }
    },
  },
};
