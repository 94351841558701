
import { staticPath } from '@/data/helpers';
import segmentAnalytics from '@/libs/events';
import SsrCarousel from 'vue-ssr-carousel'
import 'vue-ssr-carousel/index.css';

export default {
  name: 'WhyJoinTheMighty',
  components: { SsrCarousel },
  data() {
    return {
      slideIdx: 0,
    }
  },
  computed: {
    // carouselSettings() {
    //   return {
    //       "dots": true,
    //       "arrows": false,
    //       "variableWidth": false,
    //       "centerMode": true,
    //       "dotsClass": "slick-dots",
    //       "edgeFriction": 0.35,
    //       "infinite": true,
    //       "autoplay": true,
    //       "autoplaySpeed": 7000,
    //       "speed": 500,
    //       "slidesToShow": 1,
    //       "slidesToScroll": 1
    //   }
    // },
  },
  methods: {
    staticPath,
    previous() {
      if (this.slideIdx === 0) {
        this.$refs.carousel.goTo(2);
        return;
      }
      this.$refs.carousel.prev();
    },
    next() {
      if (this.slideIdx === 2) {
        this.$refs.carousel.goTo(0);
        return;
      }
      this.$refs.carousel.next();
    },
    onChange(idx) {
      this.slideIdx = idx;
    },
    openRegistration(action) {
      this.$store.dispatch('openModal', {
        id: 'login-registration-onboarding',
        args: { action },
      });

      segmentAnalytics.choose({
        target: {
          name: 'Join Us',
          type: 'button',
        },
        context: {
          section: 'landing_page',
        }
      });
    },
  },
};
