
export default {
  props: ["src", "classes", "styles", "tag", "href", "linkTitle", "navigate"],
  data: () => ({ observer: null, intersected: false }),
  computed: {
    srcImage() {
      return this.intersected ? this.src : "";
    },
  },
  mounted() {
    this.observer = new IntersectionObserver((entries) => {
      for (const entrie of entries) {
        if (entrie.isIntersecting) {
          this.intersected = true;
          this.observer.disconnect();
        }       
      }
    });

    this.observer.observe(this.$el);
  },
  destroyed() {
    this.observer.disconnect();
  },
  methods: {
    onClick(e) {
      if(this.navigate) {
        this.$emit("onClick", e, this. navigate);
      }
    }
  }
};
