
// libs
import $ from 'jquery';
import { log } from '@/data/helpers';
import Lockable from '@/mixins/lock-on-scroll';
import Modal from '../Modal';

export default {
  name: 'BlockModal',
  mixins: [Lockable],
  props: {
    internalCloseX: {
      default: true,
    },
    externalCloseX: {
      default: false,
    },
    canClickBackdrop: {
      default: true,
    },
  },
  components: { Modal },

  methods: {
    slotEmpty(slotName) {
      return !this.$slots[slotName] || !this.$slots[slotName].length;
    },

    close() {
      this.$emit('close');
    },
  },
  mounted() {
    // window.tm_update_menu_tab_indexes(); TODO
    $('.tm-sh-icon.external-close-x').focus();
  },
};

