export default {
  data() {
    return {
      navbarHeight: 0,
    };
  },
  mounted() {
    window.addEventListener('resize', this.calculateHeight);
    this.calculateHeight();
  },
  unmounted() {
    window.removeEventListener('resize', this.calculateHeight);
  },
  methods: {
    calculateHeight() {
      const p = document.getElementById('scroll-shim');
      const style = p.currentStyle || window.getComputedStyle(p);
      this.navbarHeight = style.marginTop;
    },
  },

};
