
import _ from 'lodash';
import PostHeartsButton from '@/components/PostHeartsButton';
import PostReactionsButton from '@/components/PostReactionsButton';
import PostBookmarkButton from '@/components/PostBookmarkButton';
import PostCommentsButton from '@/components/PostCommentsButton';
import PostShareButton from '@/components/PostShareButton';

export default {
  name: 'CardFooter',
  props: ['index', 'post', 'options', 'mode', 'gaCategory', 'segmentData', 'menuOpened', 'toggleMenu'],
  components: { PostHeartsButton, PostReactionsButton, PostCommentsButton, PostShareButton, PostBookmarkButton },
  data() {
    return {
      cardTypeHash: {
        WORDPRESS_POST: 'story',
        THOUGHT: 'thought',
        QUESTION: 'question',
      },
    };
  },
  computed: {
    engagementBarProps() {
      return {
        post: this.post,
        options: this.options,
        gaCategory: `${this.gaCategory}.engagement_bar`,
      }
    },
    engagementBarSegmentData() {
      return _.merge({}, this.segmentData, {
        context: {
          module: 'engagement_bar',
        },
      });
    },
    showReactionButton() {
      return ['THOUGHT', 'QUESTION', 'POST'].includes(_.get(this.post, 'type', ''));
    },
    hasCommentsEnabled() {
      return this.post.comment_status
          && this.post.comment_status === 'open';
    },
    reactionsSegmentData() {
      return _.merge({}, this.engagementBarSegmentData, {
        target: {
          name: 'reaction',
          type: 'button',
        },
      });
    },
    reactionsProps() {
      return {
        ...this.engagementBarProps,
        segmentData: this.reactionsSegmentData,
        menuOpened: this.menuOpened,
      }
    },
    heartSegmentData() {
      return _.merge({}, this.engagementBarSegmentData, {
        target: {
          name: this.post.title,
          type: this.cardTypeHash[this.post.type],
        },
      });
    },
    heartProps() {
      return {
        ...this.engagementBarProps,
        segmentData: this.heartSegmentData,
      }
    },
    commentsSegmentData() {
      return _.merge({}, this.engagementBarSegmentData, {
        target: {
          name: 'comments',
          type: 'button',
        },
      });
    },
    commentsProps() {
      return {
        ...this.engagementBarProps,
        segmentData: this.commentsSegmentData,
        mode: this.mode,
      }
    },
    shareSegmentData() {
      return _.merge({}, this.engagementBarSegmentData, {
        target: {
          name: 'share',
          type: 'button',
        },
      });
    },
    shareProps() {
      return {
        ...this.engagementBarProps,
        segmentData: this.shareSegmentData,
        mode: this.mode,
        cardIndex: this.index,
      }
    },
    bookmarkSegmentData() {
      return _.merge({}, this.engagementBarSegmentData, {
        target: {
          name: 'bookmark',
          type: 'button',
        },
      });
    },
    bookmarkProps() {
      return {
        ...this.engagementBarProps,
        segmentData: this.bookmarkSegmentData,
      }
    },
  },
};
