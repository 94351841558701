
/* eslint no-console:0 */
// libs
import { isSet, log } from '@/data/helpers';

export default {
  name: 'CheckBox',
  props: ['args'],
  data() {
    return {
      value: '',
      id: 'tmp',
    };
  },
  watch: {
    value() {
      if (this.value) {
        this.$emit('input', this.args.value);
      } else {
        this.$emit('input', isSet(this.args.fallbackValue) ? this.args.fallbackValue : false);
      }
    },
  },
  computed: {
    isChecked() {
      return this.args.checked ? 'checked' : '';
    },
  },
  beforeMount() {
    this.value = this.args.checked ? 1 : 0;
  },
  mounted() {
    const rnd = parseInt(Math.random() * 100000000000, 10);
    this.id = `cb${rnd}`;
    log('BEFORE:', this.args, this.args.checked, this.isChecked);
  },
};
