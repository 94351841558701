
import vue, { PropType } from 'vue';
import { SponsorI } from '@/userExperienceRedesign/types/sponsor';
import LazyImage from '@/components/LazyImage/index.vue';

export default vue.extend({
  name: 'StoryTile',
  components: { LazyImage },
  props: {
    label: {
      type: String as PropType<SponsorI['label']>,
    },
    logoSrc: {
      type: String as PropType<SponsorI['logoSrc']>,
    },
    name: {
      type: String as PropType<SponsorI['name']>,
      required: false,
    },
    url: {
      type: String as PropType<SponsorI['url']>,
      required: true,
    },
  },
  computed: {
    unlinkedSponsor() {
      if (!this.url) {
        return 'div';
      }
      return 'a';
    },
    disabledLink() {
      if (!this.url) {
        return 'disabledLink';
      }
      return '';
    },
  },
});
