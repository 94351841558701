
import get from 'lodash/get';
import vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import { loadVideo } from '@/data/video-helpers';
import { staticPath } from '@/data/helpers';
import StoryTile, { sharedStoryTileProps } from '../../components/StoryTile/index.vue';
import { IPost, CommunityVideoI } from './types';

const uuidv4 = require('uuid').v4;

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window { jwplayer: any; }
}

const {
  author,
  avatarGroup,
  category,
  contentPreview,
  fullStoryURL,
  headline,
  imageSrc,
  reactionsCount,
} = sharedStoryTileProps;

export default vue.extend({
  name: 'CommunityVideoStory',
  components: { StoryTile },
  props: {
    post: {
      required: true,
      // we should create a type for this
      type: Object as PropType<IPost>,
    },
    index: {
      required: true,
    },
    options: {
      required: true,
    },
    gaCategory: {
      required: true,
    },
    segmentData: {
      required: true,
    },
    /**
     * Data for the author of the post
     */
    author,
    /**
     * An array of avatar URL strings for the people who reacted to the post
     */
    avatarGroup,
    /**
     * A brief block of introduction text to give the user a preview of what the post is
     */
    contentPreview,
    /**
     * The URL for the full post
     */
    fullStoryURL,
    /**
     * The post's title
     */
    headline,
    /**
     * The number of people who have reacted to the story.
     */
    reactionsCount,
    /*
     * The image associated with the post
     */
    imageSrc,
    /**
     * Data for how the category should be labeled
     */
    category: {
      type: category.type,
      required: true,
    },
    /**
     * When the content preview text is too long to fit onto 5 lines, a button is shown to show the full block of text.
     * Whatever text is passed here will be rendered inside the button
     */
    expandButtonLabel: {
      type: String,
      default: 'Show More',
    },
    /**
     * At the end of the preview text, there is a button that links to the full story.
     * Whatever text is passed here will be rendered inside the button
     */
    fullViewButtonLabel: {
      type: String,
      default: 'Continue Reading',
    },
    playInStream: {
      default: false,
    },
    video: {
      type: Object as PropType<CommunityVideoI>,
    },
  },
  data: () => ({
      key: uuidv4(),
      hideButton: false,
  }),
  async mounted() {
    const videoId = get(this, 'video.jw_key') || get(this, 'video.jwplayer_video_id');
    if (this.playInStream && videoId) {
      await loadVideo({ videoId, id: this.key });
    }
  },
  methods: {
    staticPath,
    formateDate() {
      if (!this.post.created_at) {
        return contentPreview;
      }
      const dateStamp = this.post.created_at.toString();
      const date = new Date(dateStamp);
      const month = date.toLocaleString('default', { month: 'long' });
      const day = date.getDate();
      const year = date.getFullYear();

      let dateString = '';
      dateString = `${month} ${day}, ${year}`;
      return dateString;
    },
    playVideo() {
      if (!this.videoUrl) {
        return;
      }

      const video:any = document.getElementById(this.iframeId);
      if (!video) {
        return;
      }

      this.hideButton = true;

      video.setAttribute("allow", "autoplay");
      video.src = `${this.videoUrl}?autoplay=1&mute=1`;
    },
  },
  computed: {
    // for older videos, ensuring they don't have video id though unlikely
    videoUrl():any {
      if (get(this, 'video.jw_key') || get(this, 'video.jwplayer_video_id')) {
        return;
      }
      return get(this, 'video.media_url');
    },
    iframeId():string {
      const postId = this.post.id || this.post._id;
      return `storyTile-video--${postId}`;
    },
  },
});
