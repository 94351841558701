
import vue from 'vue';
import OutlinedButton from '@/userExperienceRedesign/components/OutlinedButton/index.vue';
import segmentAnalytics from '@/libs/events';
import { newsLetterModuleProps } from '../types';
import LazyBackgroundImage from '@/components/LazyImage/backgroundImage.vue';

export default vue.extend({
  name: 'NewsletterTheMighty',
  components: { OutlinedButton, LazyBackgroundImage },
  props: newsLetterModuleProps,
  computed: {
    backgroundImage() {
      return `url("${this.imageSrc}")`;
    },
  },
  methods: {
    onClick() {
      const { ctaText, signUpUrl, post, index } = this;
      if (post) {
        const user = post.author;
        segmentAnalytics.choose({
          path: signUpUrl,
          post: {
            id: post.id,
            title: post.title,
            topic: {
              ids: (post.topics || []).map((t: {id: string}) => t.id),
              names: (post.topics || []).map((t: {title: string}) => t.title),
            },
            user: {
              id: user.id,
              username: user.username,
            },
            type: post.type,
            pinned: post.isPinned,
          },
          context: {
            module: 'NLM_Newsletter',
            path: window.location.pathname,
            card: {
              cta: {
                text: ctaText,
              },
              index,
            },
            view: 'topic_feed',
          },
          target: {
            name: 'module_cta',
            type: 'button',
          },
        });
      }
    },
  },
});
