
import vue from 'vue';
import StoryTile, { sharedStoryTileProps } from '@/userExperienceRedesign/components/StoryTile/index.vue';
import PeopleAreTalkingAboutThis from '@/userExperienceRedesign/components/PeopleAreTalkingAboutThis/index.vue';
import SocialButtonGroup from '@/userExperienceRedesign/components/LikeShareButtons/SocialButtonGroup.vue';
import CommunityLabel from '@/userExperienceRedesign/components/CommunityLabels/CommunityLabel.vue';

const {
  author,
  avatarGroup,
  category,
  contentPreview,
  fullStoryURL,
  headline,
  imageSrc,
  reactionsCount,
  sponsor,
} = sharedStoryTileProps;

export default vue.extend({
  name: 'SponsoredStory',
  components: { StoryTile, PeopleAreTalkingAboutThis, SocialButtonGroup, CommunityLabel },
  data: () => ({
  }),
  props: {
    post: {
      required: true,
    },
    index: {
      required: true,
    },
    options: {
      required: true,
    },
    gaCategory: {
      required: true,
    },
    segmentData: {
      required: true,
    },
    /**
     * Data for the author of the post
     */
    author,
    /**
     * An array of avatar URL strings for the people who reacted to the post
     */
    avatarGroup,
    /**
     * Data for how the category should be labeled
     */
    category,
    /**
     * A brief block of introduction text to give the user a preview of what the post is
     */
    contentPreview,
    /**
     * The URL for the full post
     */
    fullStoryURL,
    /**
     * The post's title
     */
    headline,
    /**
     * The image associated with the post
     */
    imageSrc,
    /**
     * The number of people who have reacted to the story.
     */
    reactionsCount,
    /**
     * Data for how the category should be labeled
     */
    sponsor,
    /**
     * When the content preview text is too long to fit onto 5 lines, a button is shown to show the full block of text.
     * Whatever text is passed here will be rendered inside the button
     */
    expandButtonLabel: {
      type: String,
      default: 'Show More',
    },
    /**
     * At the end of the preview text, there is a button that links to the full story.
     * Whatever text is passed here will be rendered inside the button
     */
    fullViewButtonLabel: {
      type: String,
      default: 'Continue Reading',
    },
    cameFrom: {
      default: '',
    },
  },
});
