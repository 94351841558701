
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { genOpenLinkCallback, genAnalyticsUtmData, getItemPermalink, stripHtml, staticPath } from '@/data/helpers';
import clipboardLink from '@/mixins/clipboard-link';
import segmentAnalytics, { getSegmentGroupCard, getSegmentGroupFeedView, getSegmentGroupTarget } from '@/libs/events';
import { shareStructure } from '@/shared/utils/segmentData';

export default {
  name: 'PostShareButton',
  props: ['cardIndex', 'post', 'options', 'mode', 'gaCategory', 'segmentData'],
  mixins: [clipboardLink],

  data() {
    return {
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      getCurrentFullPostSegmentData: 'getCurrentFullPostSegmentData',
    }),
    card_data() {
      return this.post.card_data || {};
    },
    postUrl() {
      return getItemPermalink(this.post);
    },
    postTitle() {
      /* eslint no-underscore-dangle: 0 */
      return this.card_data['card-title'] || stripHtml(this.post._normalized_title).trim();
    },
    article() {
      return {
        url: this.postUrl,
        title: this.postTitle,
        media_url: this.card_data['card-media-url'],
      };
    },
    sharer() {
      // adds utm tracking to urls
      const facebookLink = encodeURIComponent(this.addUtmLinkData(this.article.url, 'facebook'));
      const pinterestLink = encodeURIComponent(this.addUtmLinkData(this.article.url, 'pinterest'));
      const twitterLink = encodeURIComponent(this.addUtmLinkData(this.article.url, 'twitter'));
      const emailLink = encodeURIComponent(this.addUtmLinkData(this.article.url, 'email'));

      const postTitle = encodeURIComponent(this.article.title);

      return {
        facebook: `https://www.facebook.com/sharer/sharer.php?u=${facebookLink}`,
        pinterest: `https://pinterest.com/pin/create/button/?url=${pinterestLink}&description=${postTitle}&media=${this.article.media_url}`,
        twitter: `https://twitter.com/intent/tweet?url=${twitterLink}&text=${postTitle}`,
        email: `mailto:?body=${emailLink}&subject=${postTitle}`,
      };
    },
    noPinterest() {
      return !!this.mode;
    },
    isPrivate() {
      if (this.post && this.post.status) {
        return this.post.status.type === 'PRIVATE';
      }

      return false;
    },
  },
  methods: {
    staticPath,
    toggleShareMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      this.$events.emit('toggleShareMenu', this.isMenuOpen, this.post);
      if (this.isMenuOpen) {
        this.$store.commit('triggerCardShareMenu', {
          gaCategory: this.gaCategory,
          post: this.post,
          options: this.options,
        });
        const { view, section } = this.segmentData.context;

        const context = {
          view,
          section,
        };

        if (this.post.groups[0]) {
          context.group = {
            id: this.post.groups[0]._id,
            name: this.post.groups[0].name,
          };
        }

        segmentAnalytics.choose({
          userId: this.user && this.user._id,
          sessionId: this.user && this.user.sessionId,
          context,
          post: {
            id: this.post.id,
            type: this.post.type,
            ...this.post.type === 'WORDPRESS_POST' && Array.isArray(this.post.topics) ? {
              topic0: this.post.topics && this.post.topics[0],
              primary_topic: this.post.card_data && this.post.card_data['card-author-byline'],
            } : {},
            topic: {
              names: (this.post.topics || []).map(t => t.title),
            },
            title: this.post.title,
            user: {
              username: this.post.author?.username,
              id: this.post.author?.id,
            },
          },
          target: {
            type: 'icon',
            name: 'share',
          },
        });
      }
    },
    triggerShareMenuButton(mediaName, e) {
      this.$store.commit('triggerCardShareMenuButton', {
        gaCategory: this.gaCategory,
        post: this.post,
        options: this.options,
        mediaName,
        callback: genOpenLinkCallback(e.currentTarget, e),
      });

      segmentAnalytics.choose(_.merge({}, this.segmentData, {
        target: {
          name: mediaName,
        },
      }));

      const { post } = this;
      const viewName = this.segmentData.context.view.name;

      const segmentData = shareStructure(post, this.user, mediaName);

      segmentAnalytics.share({
        ...segmentData,
      });

    },
    addUtmLinkData(link, medium) {
      const utmDefaultData = genAnalyticsUtmData({
        utm_source: 'engagement_bar',
        utm_campaign: `${this.options.utmCampaignPrefix}.engagement_bar`,
      });

      return utmDefaultData(link, { utm_medium: medium });
    },
  },
  events: {
    /**
     * Global event that listens for close menu action
     * (click on the card or anywhere outside the share menu)
     */
    closeShareMenu() {
      if (this.isMenuOpen) {
        this.toggleShareMenu();
      }
    },
  },
};
