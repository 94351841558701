
import { staticPath } from "@/data/helpers";

export default {
    name: "ModuleFeature",
    props: ['module'],
    data() {
        return {
            visible: true,
        }
    },
    methods: {
        staticPath,
    },
}
