
import vue from 'vue';
import SaveIcon from '@/userExperienceRedesign/components/Icon/saveIcon.vue';

export default vue.extend({
  name: 'PostSaveButton',
  components: { SaveIcon },
  props: {
    /** Asks if save button is on dark background. Will affect icon and text color  */
    isOnDarkBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updateTextColor() {
      if (this.isOnDarkBg) {
        return 'socialButton--onDark';
      }
      return '';
    },
  },
  methods: {

  },
});
