// eslint-disable-next-line import/prefer-default-export
import moment from 'moment';

export const removeHtmlStructure = (content: String) =>
  content.replace(/(<([^>]+)>)/gi, '').replace(/&#8217;/g, "'").replace(/&#8220;/g, '"').replace(/&#8221;/g, '"')
  .replace(/&#8212;/g, '—');

export const timeLeft = (post: any) => {
  const { end_at } = post;

  const daysToClose = Math.abs(moment(end_at).diff(new Date(), 'days') + 1);

  if (daysToClose === 1) {
    const hoursToClose = Math.abs(moment(end_at).diff(new Date(), 'hours') + 1);
    if (hoursToClose === 1) {
      return '1 hour left!'
    }
    return `${hoursToClose} hours left`;
  }

  return `${daysToClose} days left`;
};
