
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { humanNumberFormatWithOptions, staticPath } from '@/data/helpers';
import { heartStructure } from '@/shared/utils/segmentData';

export default {
  name: 'PostReactionsButton',
  props: ['post', 'options', 'gaCategory', 'segmentData', 'menuOpened'],
  data() {
    return {
      // using this to disable continuous hearting/unhearting
      canReact: true,
      reactionMenu: false,
      hightlighted: null,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
      userReactions: 'userReactions',
    }),
    postId() {
      if (this.post.type && this.post.type.indexOf('WORDPRESS') > -1) {
        return parseInt(this.post._id, 10);
      }
      return this.post.id;
    },

    reactions() {
      return [
        { name: 'heart', label: 'Love this', reaction_type: 'HEART' },
        { name: 'bulb', label: 'This is so helpful', reaction_type: 'HELPFUL' },
        { name: 'sun', label: 'You are not alone', reaction_type: 'NOT_ALONE' },
        { name: 'hand', label: 'I’ve been there', reaction_type: 'BEEN_THERE' },
        { name: 'battery', label: 'Sending you energy', reaction_type: 'ENERGY' },
        { name: 'balloons', label: 'Cheering you on!', reaction_type: 'CHEER' },
      ]
    },
    selectedReaction() {
      // the purpose of the userReactions is so that there is consistency between FullPost and PostCards
      const idx = _.findIndex(this.userReactions, r => r.postId === this.postId);
      if (idx > -1) {
        return _.find(this.reactions, r => r.reaction_type === this.userReactions[idx].reaction);
      }

      return _.find(this.reactions, r => r.reaction_type === _.get(this.post, 'reaction_type'));
    },
  },
  methods: {
    staticPath,
    openReactionMenu() {
      // this refers to the Reaction Breakdown count on the CardStats component, which we should close if this other Reaction Menu opens
      if (this.menuOpened) {
        this.$emit('toggleMenu');
      }
      this.reactionMenu = true
    },
    highlightSelected() {
        const idx = _.findIndex(this.reactions, r => r.name === this.selectedReaction.name);
        this.hightlighted = idx;
    },
    handleFocus(idx, setting) {
      if (setting === 'in') {
        this.hightlighted = idx;
      } else if (idx === this.hightlighted) {
        this.hightlighted = null;
      }
    },
    async removeReaction() {
      const post = {
        reaction: null,
        postId: this.postId,
        postType: this.post.type,
      };
      await this.$store.commit('setUserReaction', post);
      await this.$store.dispatch('removeUserReaction', {
        postId: this.postId,
        postType: this.post.type,
        reaction: null,
      });
      this.reactionMenu = false;
    },
    // giving the closing some delay
    closeReactionMenu() {
      setTimeout(() => { this.reactionMenu = false; }, 500);
    },
    async selectReaction(idx) {
      // trying to stop them from reacting to multiple times
      if (!this.canReact) {
        return;
      }

      this.canReact = false;
      const post = {
        reaction: this.reactions[idx].reaction_type,
        postId: this.postId,
        postType: this.post.type,
      };

      // if no existing selection, add new one
      if (!this.selectedReaction) {
        await this.$store.commit('setUserReaction', post);
        this.closeReactionMenu();
        await this.$store.dispatch('addUserReaction', post).then(() => { this.canReact = true; });
        return;
      }

      // if selected existing selection, remove it
      if (post.reaction === this.selectedReaction.reaction_type) {
        const noReactionPost = { ...post, reaction: null };
        await this.$store.commit('setUserReaction', noReactionPost);
        this.closeReactionMenu();
        await this.$store.dispatch('removeUserReaction', noReactionPost).then(() => { this.canReact = true; })
        return;
      }

      await this.$store.commit('setUserReaction', post);
      this.closeReactionMenu();
      // otherwise remove the old one and add the new one
      await this.$store.dispatch('removeUserReaction', post)
        .then(async () => {
          await this.$store.dispatch('addUserReaction', post);
          this.canReact = true;
        });
    },
    humanNumberFormatWithOptions,
  },
};
