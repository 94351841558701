
import vue from 'vue';
import ShareIcon from '@/userExperienceRedesign/components/Icon/shareIcon.vue';

export default vue.extend({
  name: 'PostShareButton',
  components: { ShareIcon },
  props: {
    /** Boolean that will change icon/text color to white if true */
    isOnDarkBg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    updateTextColor() {
      if (this.isOnDarkBg) {
        return 'socialButton--onDark';
      }
      return '';
    },
  },
});
