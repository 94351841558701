
import { isObject, isSet } from "@/data/helpers";

export default {
  name: "SmartBadge",
  props: {
    page: {
      default: '',
    },
    type: {
      default: "",
    },
    post: {
      default() {
        return {
          type: "",
        };
      },
    },
    feed: {
      // Props with type Object/Array must use a factory function to return the default value.
      default() {
        return {};
      },
    },
  },

  computed: {
    argsHasClass() {
      if (isSet(this.args.classes)) {
        return this.args.classes;
      }
      return null;
    },
    cardType() {
      // check the post first
      if (isObject(this.post) && this.post.type && this.post.type.length) {
        return this.post.type;
      }

      // otherwise check the passed in type, if any
      if (this.type && this.type.length) {
        return this.type;
      }

      // default to story
      return "WORDPRESS_POST";
    },

    args() {
      if (!isObject(this.post)) {
        return {
          text: "",
          classes: "",
        };
      }

      // check type
      switch (this.cardType) {
        case "WORDPRESS_VIDEO":
        case "video":
          return {
            text: "Video",
            classes: "story",
          };
        case "WORDPRESS_POST":
        case "story":
        case "post":
          return {
            text: "Story",
            classes: "story",
          };
        case "THOUGHT":
          if (this.feed.group_status_type === "PRIVATE") {
            let text;
            let classes;

            switch (this.feed.group_moderation_status) {
              case "PENDING":
                text = "Pending Community Leader Review";
                classes = "pending_leader_review";
                break;
              case "MODERATED":
                text = "Moderated by Community Leader";
                classes = "moderated_by_cl";
                break;
              case "MODERATED_BY_MM":
                text = "Moderated by Mighty Moderator";
                classes = "moderated_by_mm";
                break;
              case "READY":
                text = "Ready for Mighty Moderation";
                classes = "ready_for_mighty_moderation";
                break;
              default:
                // text = 'Thought';
                // classes = 'thought';
                text = "Post";
                classes = "post";
            }

            return {
              text,
              classes,
            };
          }

          return {
            // text: 'Thought',
            // classes: 'thought',
            text: "Post",
            classes: "post",
          };
        case "QUESTION":
          return {
            text: "Post",
            classes: "post",
          };
        case "CHAT":
          return {
            text: "Direct Message",
            classes: "chat",
          };
        case "USER":
          return {
            text: "User",
            classes: "user",
          };
        case "POLL":
          return {
            text: "Poll",
            classes: this.page === 'moderationFeed' ? "poll-moderation" : 'poll',
          };
        case "GROUP":
          return {
            text: "Community Details",
            classes: "group",
          };
        default:
      }
      // other badge defaults (like T + Q) will take care of it
      return {
        text: "",
        classes: "",
      };
    },
  },
};
