
import _ from 'lodash';
import { mapGetters } from 'vuex';
import FormButton from '@/components/FormButton';

export default {
  name: 'FeedCardImage',
  props: ['post', 'hideBorders'],
  components: { FormButton },

  data() {
    return {
      biggerImageSource: false,
    };
  },

  computed: {
    ...mapGetters({
      acceptedImage: 'hasAcceptedImage',
    }),

    hasImages() {
      // deny image render on moderated cards
      if (['REJECTED', 'MODERATION', 'DELETED'].indexOf(this.post.status.type) > -1) {
        return false;
      }
      return this.post.embed_counts.images > 0;
    },

    img() {
      return this.post.embeds.images[0];
    },

    showImage() {
      const state = {};
      // get all the default states from the post data
      if (this.post.embeds && Array.isArray(this.post.embeds.images)) {
        this.post.embeds.images.forEach((img) => {
          state[_.get(img, '_id')] = _.get(img, 'safe');
        });
      }

      // update based on the user settings
      Object.keys(this.acceptedImage).forEach((k) => {
        state[k] = this.acceptedImage[k];
      });

      return state;
    },
    imageId() {
      return _.get(this.img, '_id');
    },
    imageUrl() {
      return _.get(this.img, 'url');
    },
    isUnsafe() {
      return !_.get(this.img, 'safe');
    },

  },

  methods: {
    toggleNeedsBlur() {
      const setTo = !this.acceptedImage[this.imageId];
      this.$store.commit('setAcceptedImage', { id: this.imageId, accept: setTo });
    },
    calculateImageSource() {
      if (this.$refs.image) {
        this.$emit('image-loaded', this.$refs.image.clientHeight);
        this.biggerImageSource = this.$refs.image.clientHeight > 500 && this.$refs.image.clientHeight > this.$refs.imageWrap.clientHeight;
      }
    },
  },
};
