
import { get } from 'lodash';
import { AuthorI } from '@/userExperienceRedesign/types/author';
import { mapGetters } from 'vuex';
import vue, { PropType } from 'vue';
import MaybeLink from '../../../components/MaybeLink/index.vue';
import { FOLLOW_TYPE_USER } from '@/data/enum/follow';

export default vue.extend({
  name: 'StoryAuthorByline',
  components: { MaybeLink },
  props: {
    author: {
      type: Object as PropType<AuthorI>,
      required: true,
    },
    /** Boolean to determine whether or not to show author role if on Community Highlights */
    hideTitle: Boolean,
    category: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
    sponsored: {
      type: Object as PropType<any>,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
      isLoggedIn: 'isLoggedIn',
      sessionLoaded: 'sessionLoaded',
    }),

    userIsLoggedIn() {
      return this.isLoggedIn && this.sessionLoaded;
    },
    
    followed() {
      return this.$store.getters.userFollowedUser(this.author.authorId);
    },
    showFollow() {
      return !this.followed && this.author.authorId !== this.user._id;
    },

    categoryName() {
      return get(this.category, 'name');
    },

    categoryLink() {
      const slug = get(this.category, 'slug');
      if (!slug) {
        return;
      }
      return `/topic/${slug}`;
    },

    isSponsored() {
      return this.sponsored && this.sponsored.is;
    },
  },
  methods: {
    handleFollowAction() {
      // User not logged in
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('notLoggedInAction');
        return;
      }

      this.$store.dispatch('toggleFollow', {
        itemType: FOLLOW_TYPE_USER,
        itemId: this.author.authorId,
        initiator: 'instantFollowCTA',
        followName: this.author.authorHandle,
        following: this.followed,
      });
    },
  },
});
