
/* eslint no-underscore-dangle:0 */
import _ from 'lodash';
import { mapGetters } from 'vuex';
import NormalizeAuthor from '@/mixins/normalize-author';
import MoreLess from '@/mixins/more-less';
import Notification from '@/components/Notification';
import FeedCardActionMenu from '@/feed/FeedCard/FeedCardActionMenu';
import { log, isSet, sameDomainUrl, selectorMatches, doesUserOwnPost, siteUrl, getEmbeddedLinks } from '@/data/helpers';
import { cleanHTML, sanitizeText } from '@/data/card-helpers';
import CardFooter from '@/feed/FeedCard/CardFooter';
import CardStats from '@/feed/FeedCard/CardStats';
import CardComment from "@/feed/CardComment";
import ContentCard from '../ContentCard';
import CardByline from '../FeedCard/CardBylineSmall';
import CardImage from '../FeedCard/FeedCardImage';
import ExternalLinkPreview from '../ExternalLinkPreview';

const cardElementsAllowedFocus = 'a, .action-menu, .card-share-menu, .feed-card.sharing, .jwplayer, input, textarea, button, .menu-item a';

export default {
  name: 'PostCard',
  mixins: [MoreLess, NormalizeAuthor],
  components: { FeedCardActionMenu, ContentCard, CardByline, CardFooter, Notification, CardImage, CardComment, ExternalLinkPreview, CardStats },

  // passed in properties
  props: {
    feedName: {
      default: '',
    },
    post: {
      type: Object,
      required: true,
    },
    options: {
      humanNumber: true,
      humanNumberFirstValue: true,
      analyticsEventData: {},
    },
    gaCategory: {
      default: '',
    },
    index: undefined,
    segmentData: undefined,
  },

  // properties that require logic
  computed: {
    ...mapGetters({
      user: 'user',
      getMygroups: 'getMygroups',
    }),
    saneTitle() {
      const { title, topics, mentions, embeds } = this.post;
      return sanitizeText({ content: title, topics, mentions, embeds });
    },

    // filter for embedded links which have images to preview
    embeddedLinks() {
      return getEmbeddedLinks(this.post);
    },

    hasEmbeddedLink() {
      return !_.isEmpty(this.embeddedLinks);
    },

    saneBody() {
      const { body, topics, mentions, embeds } = this.post;
      return sanitizeText({ content: cleanHTML(body || ''), topics, mentions, embeds });
    },
    replyPlaceHolder() {
      return `Reply to ${this.author.byline}`;
    },

    showContent() {
      return this.post.status.type !== 'REJECTED';
    },

    // does this user own this item
    ownsItem() {
      return doesUserOwnPost(this.user, this.post);
    },

    hasImages() {
      return isSet(this.post.embed_counts) && this.post.embed_counts.images > 0;
    },
    // on a user's profile page (as owner), displaying the user's comment below the Post Card
    // this is being done at src/profile/LegacyProfile/ProfileContent/ProfileStories.vue and src/profile/LegacyProfile/ProfileContent/ContentTabs/ProfileMentions.vue
    hasCommentToShow() {
      return this.post.comment && this.post.comment._id;
    },
    
    editedPost() {
      return _.get(this.post, 'edited', false);
    }
  },

  // internal data
  data() {
    return {
      maxHeight: 48,
      alreadySubmitted: false,
      showReactionBreakdown: false,
    };
  },

  // handle events and such
  methods: {
    // handleWasSubmitted(newComment) {
    handleWasSubmitted() {
      this.alreadySubmitted = sameDomainUrl(`/#post=${this.post.id}&comments=1`);
    },

    /**
     * Determines if the given card element accepts focus
     * Some elements (defined in `cardElementsAllowedFocus`)
     *   can also have the focus in front of the card itself
     */
    isCardElementFocusAllowed(element) {
      return selectorMatches(element, cardElementsAllowedFocus) ||
        (element.closest(cardElementsAllowedFocus) || []).length > 0;
    },

    /**
     * Opens the card link when any place in card is clicked (not sharing)
     * The redirection only happens for clicks in non actionable elements
     */
    openCardLink(e) {
      const className = _.get(e, 'target.parentElement.className');
      if (className === 'link-preview') {
        // if click on link Preview, don't open full post
        return;
      }

      if (!this.isCardElementFocusAllowed(e.target) && this.post.status.type !== 'REJECTED') {
        this.$store.dispatch('updateCurrentFullPostSegmentData', { data: { ...this.segmentData, cardIndex: this.index } });
        this.$store.dispatch('updateCurrentFullPost', { data: this.post, options: { profilePage: this.segmentData.context.view === 'profile_feed' } });
        this.$store.dispatch('openModal', { id: 'view-full-post' });
      }
    },

    postContentLinkClick(e) {
      if (e.target && e.target.href && (e.target.classList.contains('tm-topic-link') || e.target.classList.contains('tm-mention-link'))) {
        e.preventDefault();
        this.$router.push(siteUrl(e.target.href, true));
      }
    },
    toggleReactionBreakdownMenu() {
      this.showReactionBreakdown = !this.showReactionBreakdown;
    },
  },
};
