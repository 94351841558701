
import { staticPath } from '@/data/helpers';

export default {
  name: 'LookingToCollaborate',
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    staticPath,
  },
};
