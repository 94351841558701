
import vue from 'vue';
import PostHeartsButton from './PostHeartsButton/PostHeartsButton.vue';
import PostShareButton from './PostShareButton/PostShareButton.vue';
import PostCommentButton from './PostCommentButton/PostCommentButton.vue';
import PostSaveButton from './PostSaveButton/PostSaveButton.vue';

export default vue.extend({
  name: 'SocialButtonGroup',
  components: {
    PostHeartsButton,
    PostShareButton,
    PostCommentButton,
    PostSaveButton,
  },
  props: {
    /** Accepts string that will be displayed in the share button  */
    shareText: {
      type: String,
      default: 'Share',
    },
    /** Numeric value for like counts  */
    likeCount: {
      type: Number,
    },
    /** Numeric value for comment counts  */
    commentCount: {
      type: Number,
    },
    /** Boolean to determine whether component is on a dark background, if true, icons and text
     * will change to white */
    isOnDarkBackground: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickShare() {
      this.$emit('onClickShare');
    },
    onClickComment() {
      this.$emit('onClickComment');
    },
    onClickLike() {
      this.$emit('onClickLike');
    },
    onClickSave() {
      this.$emit('onClickSave');
    },
  },
  computed: {
    updateBorders() {
      if (this.isOnDarkBackground) {
        return 'social-update-borders';
      }
      return '';
    },
  },
});
