
import vue, { ComputedOptions } from 'vue';
import { darken, readableColor, getContrast } from 'color2k';

const getTextColorFromBgColor = (bgColor: string) => {
  const textColor = darken(bgColor, 0.6);
  const contrast = getContrast(bgColor, textColor);

  if (contrast < 3) {
    return readableColor(bgColor);
  }

  return textColor;
};

export default vue.extend({
  // NOTE: Hard-coding values while static
  name: 'CommunityLabel',
  data: () => ({
    iconSize: 14,
  }),
  props: {
    /** Accepts a color string for the color the label will be filled with */
    labelTitle: {
      type: String,
      required: true,
      // default: 'Mental Health',
    },
    /** Boolean that toggles icon on or off */
    showIcon: {
      type: Boolean,
      default: true,
    },
    /** Accepts a color string for the label background */
    backgroundColor: {
      type: String,
      required: true,
      // default: '#FEE4FF',
    },
    /**
     * Accepts color string - for the label text color and icon fill color.
     * If no background color is chosen, a slightly darker version of the background color will be used.
     * If the calculated text color has insufficient contrast with the background, black or white text will be used.
     * If a `borderColor` is passed, that color will be used for the text color.
     */
    textColor: {
      type: String,
      default: '#893c8d',
    },
    /**
     * String that sets a colored border around the label and removes the background color.
     * This color is also used for the label text and icon fill color
     */
    borderColor: {
      type: String,
    },
    /**
     * A URL that links to the community.
     */
    url: {
      type: String,
    },
  },
  computed: {
    getLabelBadgeStyles() {
      const styles: Record<
        'backgroundColor' | 'color',
        string | (() => any) | ComputedOptions<string>
      > = { backgroundColor: '#FFFFFF', color: '#000000' };

      if (this.backgroundColor) {
        styles.backgroundColor = this.backgroundColor;
        styles.color = this.textColor ? this.textColor : getTextColorFromBgColor(this.backgroundColor);
      }

      if (this.borderColor) {
        styles.backgroundColor = 'transparent';
        styles.color = this.borderColor;
      }

      return styles;
    },
    type() {
      if (this.url) {
        return 'a';
      }
      return 'span';
    },
  },
});
