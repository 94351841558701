
import vue, { PropType } from 'vue';
import { OutlinedButtonPropsI } from './types';

export default vue.extend({
  name: 'OutlinedButton',
  props: {
    isOnDarkBg: {
      type: Boolean as PropType<OutlinedButtonPropsI['isOnDarkBg']>,
      default: false,
    },
    isFullWidth: {
      type: Boolean as PropType<OutlinedButtonPropsI['isFullWidth']>,
      default: false,
    },
    href: {
      type: String as PropType<OutlinedButtonPropsI['href']>,
      default: null,
    },
  }
});
