
import $ from 'jquery';

export default {
  name: 'Dropdown',
  props: ['options', 'value'],
  data() {
    return {
      open: false,
      dropdownVal: this.value,
    };
  },
  mounted() {
    $(window).scroll(() => {
      this.closeDropdown();
    });
  },
  computed: {
    hasData() {
      return this.options.length > 0;
    },
  },
  methods: {
    selectOption(index) {
      this.dropdownVal = index;
      this.$emit('input', this.dropdownVal);
      this.open = false;
    },
    toggleDropdown(e) {
      e.preventDefault();
      (this.open ? this.closeDropdown : this.openDropdown)();
    },
    openDropdown() {
      this.open = true;
      $(document).bind('click.openDropdown', (event) => {
        if (!this.$el.contains(event.target)) { // click outside
          this.closeDropdown(event.target);
          event.stopPropagation();
        }
      });
    },
    closeDropdown(target) {
      if (this.open) {
        this.open = false;
        this.$refs.selected.blur();
        if (target && !target.closest('.dropdown')) {
          $(document).unbind('click.openDropdown');
        }
      }
    },
  },
};
